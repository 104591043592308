import { useState, useEffect, useRef } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
    Elements
} from '@stripe/react-stripe-js';
import {getClientSecret, getCheckoutPageData} from '../service/dataService';
import Navbar from '../components/navbar';
import CheckoutForm from '../components/checkoutForm';
import styles from '../styles/css/Checkout.module.css';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Oval } from  'react-loader-spinner'
import spot from '../assets/images/spotlyt_logo.png';
import Footer from '../components/footer';
import insta from '../assets/images/instagram_wordmark_black.png';
import hrt from '../assets/images/noun_Heart_682473 (1).svg';
import PayNav from '../components/payNav';
import PageNotFound from './pageNotFound';

const stripePromise = loadStripe('pk_live_XNUSEHgtg706vs1gN4guoHMZ007MYUFuWn');

const CheckoutPage = () => {
    const { userName, adId, type, email } = useParams();
    const [imgUrl, setImgUrl] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [price, setPrice] = useState('');
    const [customerEmail, setCustomerEmail] = useState(email);
    const [show, setShow] = useState(false);
    const myRef = useRef();
    const [error, setError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [clientSecret, setClientSecret] = useState({
        clientSecret: '',
        appearance: {
            theme: 'night',
            variables: {
                
            },
            rules: {
            //   '.Input, .Block': {
            //     border: '1.5px solid #7B64ED'
            //   }
            }
        }
    });

    const fetchClientSecret = async () => {
        try{
            setLoading(true);
            if(clientSecret.clientSecret != '')
                return;
            const adData = await getClientSecret(userName, adId, type, customerEmail);
            if(adData.error){
                setError(true);
                return;
            }
            setClientSecret(prev =>  {return {...prev, clientSecret: adData.client_secret}})
            setShow(true);
            setLoading(false);
        } catch(err){
            console.log(err);
            setLoading(false);
        }
    }

    const fetchPaymentData = async () => {
        try{
            const adData = await getCheckoutPageData(userName, adId, type);
            if(adData.error){
                setError(true);
                return;
            }
            setImgUrl(adData.img_url);
            setVideoUrl(adData.vid_url)
            setPrice(adData.price);
        } catch(err){
            console.log(err);
        }
    }

    useEffect(() => {
        fetchPaymentData();
    }, [])

    useEffect(() => {
        if(show && myRef){
            myRef.current.scrollIntoView();
        }
    }, [show])

    if(error){
        return (
            <div className={styles.container}>
                <PayNav/>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', color: 'white'}}>
                <div>Sorry, this page isn't available.</div>
            </div>
            </div>
        )
    }

    if(price == ''){
        return <div className={styles.loading}>
            <Oval
                height={80}
                width={80}
                color="white"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="white"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        </div>
    }
    return (
        <div className={styles.container}>
            <PayNav/>
            <div className={styles.mid}>
                <div className={styles.review}>
                    Review your purchase, below.
                </div>
                <div className={styles.whiteCont}>
                {imgUrl != "" && <img src={imgUrl} className={styles.im}/>}
                {videoUrl && videoUrl != "" && <video src={videoUrl} controls className={styles.im}/>}
                <div className={styles.rightDiv}>
                    <div className={styles.userNameDiv}>
                        <img src={spot} className={styles.spot}/>
                        @{userName}
                        <div style={{fontSize: '2vw'}}>
                            <img src={hrt} className={styles.hrt}/>
                    </div>
                    </div>
                    <div className={styles.instaText}>Post to my&#160;<span><img src={insta} className={styles.instaLogo}/></span></div>
                    <div className={styles.tagDiv}>
                    {type == "Post to Story" ?  <div className={styles.tags}>
                            Post to my Story
                        </div>: type == "Post to Wall" ? 
                        <div className={styles.tags}>
                            Post to my Wall
                        </div> :
                        <>
                        <div className={styles.tags} >
                        Post to my Wall
                    </div>
                    <div className={styles.tags} style={{marginLeft: '10px'}}>
                    Post to my Story
                </div></>
                        }
                    </div>
                    <div className={styles.priceText}><span style={{fontFamily: 'proxima'}}></span>${price}</div>
                    <div className={styles.text}>
                        Please allow {userName} up to 48 hours to post your content. &#160;Stories stay up 24 hours and
                        wall posts stay up minimum of seven (7) days.
                    </div>
                    {/* <div style={{marginTop: '10px'}}>Email</div> */}
                    <input placeholder='Enter your email' className={styles.inp} onChange={(e) => {
                        setClientSecret(prev =>  {return {...prev, clientSecret: ""}});
                        setCustomerEmail(e.target.value);
                        setShow(false);
                        }} value={customerEmail}/>
                    <div className={styles.terms}>
                        By purchasing you agree to our <span style={{cursor: 'pointer', color: '#5B94F7'}} onClick={() => window.open('https://app.termly.io/document/privacy-policy/8ff5f29c-ba50-4235-848a-595facfaf87a','_blank','height=600,width=500')}>Privacy Policy</span> and <span style={{cursor: 'pointer', color: '#5B94F7'}} onClick={() => window.open('https://app.termly.io/document/terms-of-service/9eea418b-6431-431f-932e-c8550221d4c2','_blank','height=600,width=500')}>Terms.</span>
                    </div>
                    <div className={clientSecret.clientSecret == "" ? styles.pay : styles.payDisabled} onClick={async () => {
                        setEmailError(false);
                        if(customerEmail.trim() == ''){
                            setEmailError(true);
                            return;
                        }
                        if(clientSecret.clientSecret != ""){
                            return;
                        }
                        await fetchClientSecret();
                        }}>
                        {loading ?  <Oval
                          height={22}
                          width={22}
                          color="#ffffff"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#ffffff"
                          strokeWidth={2}
                          strokeWidthSecondary={2}
                        /> : 'Agree & Continue to Payment'}
                    </div>
                   {emailError && <div style={{ color: "#e06666", marginTop: '10px' }}>Please enter a valid email</div>}
                </div>
                </div>
                {show &&        
                <div className={styles.cardCont} ref={myRef}>
                    <div className={styles.payCon}>

                    {/* <div style={{color: 'white'}}>Email</div>
                    <input placeholder='Enter your email' className={styles.inp} onChange={(e) => setCustomerEmail(e.target.value)}/> */}
                    { clientSecret.clientSecret != "" &&
                        <Elements stripe={stripePromise} options={clientSecret}>
                        <CheckoutForm uid={adId} userName={userName} imageUrl={imgUrl} adPrice={price} customerEmail={customerEmail}/>
                    </Elements>}
                    </div>
                </div>
        }
                    <Footer/>

            </div>
        </div>
    )
}
export default CheckoutPage;