import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import styles from "../styles/css/Carousel.module.css";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import CarouselModal from "./carouselModal";
import { FaCirclePlay } from "react-icons/fa6";

const CustomCarousel = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [adDataToShow, setAdDataToShow] = useState({});

  const [ads, setAds] = useState([
    <div className={styles.placeCont}></div>,
    <div className={styles.placeCont}></div>,
    <div className={styles.placeCont}></div>,
    <div className={styles.placeCont}></div>
  ]);

  useEffect(() => {
    if(props.ads){
        let temp = [];
        props.ads.forEach(ele => {
        temp.push(
        <div className={styles.placeCont} onClick={() => handleAdPress(ele)}>
            {ele.image_url != "" && <img src={ele.image_url} className={styles.adIm}/>}
            {ele.video_url != "" && <div className={styles.playIcDiv}><FaCirclePlay size={50} color='#828386'/></div>}
        </div>);
        })
        let fAds = ads;
        for(let i = 0; i < temp.length; i++){
            fAds[i] = temp[i];
        }
        setAds(fAds);
    }
  }, []);

  const handleAdPress = (ele) => {
    try {
      setAdDataToShow({
        image_url: ele.image_url,
        owner: ele.owner,
        type: ele.type,
        adId: ele.adId,
        pending: ele.pending,
        is_paid: ele.is_paid,
        message: ele.message,
        video_url: ele.video_url
      });
      setIsOpen(true);
    } catch (err) {
      console.log(err);
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 768; 
  const cPerc = isMobile ? 50 : 24;
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Carousel
        showThumbs={false}
        width={"100%"}
        showIndicators={false}
        showStatus={false}
        centerMode={true}
        centerSlidePercentage={cPerc}
        autoFocus={true}
      >
        {/* {props.ads.map(ele => {
                return    (
                <div style={{ width: '15vw', borderRadius: '10px', cursor: 'pointer'}} onClick={() => handleAdPress(ele)}>
                    <img src={ele.image_url}/>
                </div>)
            })} */}
        {ads}
      </Carousel>
      <CarouselModal
        adData={adDataToShow}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        switchAd={props.switchAd}
        canApprove={props.canApprove}
      />
    </div>
  );
};
export default CustomCarousel;
