import styles from "../styles/css/UserProfile.module.css";
import Modal from "react-modal";
import { useEffect, useState, useRef } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import logo from "../assets/images/spotlyt_wordmark_gradient.png";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";
import { Oval } from "react-loader-spinner";
import Lottie from "lottie-react";
import {putWaitlist} from '../service/dataService';
import cong from "../assets/images/animation_logda3j1.json";

const JointWaitlistModal = (props) => {
    const [successSend, setSuccessSend] = useState(false);
    const [loadingSend, setLoadingSend] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState(false);

    const handleSend = async () => {
        try{
            if(email.trim() == '' || !validateEmail(email)){
                setError(true);
                setLoadingSend(false);
                return;
            }
            await putWaitlist(email);
            setSuccessSend(true)
        } catch(err){
            console.log(err)
            setError(false);
        }
    }
    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
    return (
        <Modal
        isOpen={props.modalIsOpen}
        style={{
          overlay: {
            backgroundColor: "rgb(0,0,0, 0.7)",
            zIndex: 200,
          },
          content: {
            zIndex: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            boxSizing: "border-box",
            overflow: "hidden",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "transparent",
          },
        }}
      >
        <div className={styles.modalCont}>
          <div className={styles.close} onClick={() => {
            setSuccessSend(false);
            setError(false);
             props.setIsOpen(false);
            }}>
            <AiFillCloseCircle size={25} />
          </div>
          <div>
            <img
              src={logo}
              style={{ height: "40px", marginBottom: "10px" }}
            />
          </div>
          {!successSend ? (
            <div style={{width: '100%'}}>
                <div className={styles.joinWText}>
                Spotlyt is a simple, free-to-use tool for Creators like you.  Join now, and get priority access when we launch in the coming weeks.  (Yes, it’s that soon!)
                </div>
                <input
                  placeholder="Enter your email"
                  className={styles.inp}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  style={{width: '100%'}}
                />
                <div
                  className={styles.postBtn2}
                  onClick={() => handleSend()}
                >
                  {loadingSend ? (
                    <Oval
                      height={22}
                      width={22}
                      color="#ffffff"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    "Join the List"
                  )}
                </div>
                {error && (
                  <div style={{ color: "#e06666" }}>
                    Please enter a valid email.
                  </div>
                )}
            </div>
          ) : (
            <div className={styles.confirmCont} style={{position: 'relative', zIndex: '12'}}>
              <div className={styles.congText}>
              Congrats, you're all set.  We'll let you know when Spotlyt is ready!
              </div>
              <div
                className={styles.postBtn2}
                onClick={() => {
                    setSuccessSend(false);
                    setError(false);
                    props.setIsOpen(false);
                }}
              >
                Got it
              </div>
              <div style={{position: 'absolute', zIndex: '-11'}}>
                    <Lottie animationData={cong} loop={true} />
              </div>
            </div>
          )}
        </div>
      </Modal>
    )
}
export default JointWaitlistModal;