import Modal from "react-modal";
import { AiFillCloseCircle } from "react-icons/ai";
import styles from "../styles/css/PaymentReminder.module.css";
import { Oval } from "react-loader-spinner";
import { useState } from "react";

const PaymentReminder = (props) => {
    const [payLoading, setPayLoading] = useState(false);

  return (
    <Modal
      isOpen={props.modalIsOpen}
      style={{
        overlay: {
          zIndex: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgb(0,0,0, 0.7)",
        },
        content: {
          zIndex: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          boxSizing: "border-box",
          overflow: "hidden",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "transparent",
        },
      }}
    >
      <div className={styles.modalCont}>
        <div className={styles.titles} style={{ color: "white" }}>
        Set up your payout method
        </div>
        <div className={styles.pText}>
        Please set up your payout account to accept payments for your ads and collaborations.
        </div>
        <div
          className={styles.signUpBtn}
          onClick={() => {
            setPayLoading(true);
            props.handleSetupPay();
        }}
        >
            {payLoading ? <Oval
                height={22}
                width={22}
                color="#ffffff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#ffffff"
                strokeWidth={2}
                strokeWidthSecondary={2}
            /> :
            "Set up Payments"}
        </div>
        <div className={styles.cl} onClick={() => props.setIsOpen(false)}>Close</div>
      </div>
    </Modal>
  );
};
export default PaymentReminder;
