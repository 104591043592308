import Navbar from "../components/navbar";
import styles from "../styles/css/UserProfile.module.css";
import userPr from "../assets/images/userPr.png";
import DragImage from "../components/dragImage";
import Footer from "../components/footer";
import Modal from "react-modal";
import { useEffect, useState, useRef } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import logo from "../assets/images/spotlyt_wordmark_gradient.png";
import { sendEmail, sendEmailVideo } from "../service/dataService";
import { putPendingAds } from "../service/dataService";
import { useAuth } from "../service/authContext";
import {
  uploadImage,
  uploadVideo,
  userNameExists,
  getUserEmail,
  getUserPrices,
  getUserProfilePic
} from "../service/dataService";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import insta from "../assets/images/instagram_wordmark_white.png";
import PolaroidProfile from "../components/polaroidProfile";
import { Oval } from "react-loader-spinner";
import PageNotFound from "./pageNotFound";
import Lottie from "lottie-react";
import cong from "../assets/images/animation_logda3j1.json";
import LearnMoreModal from "../components/learMoreModal";
import LearnMoreUserProfile from "../components/learnMoreUserProfile";
import bulb from "../assets/images/spotlyt_logo.png";
import { FaRegCopy } from "react-icons/fa6";
import { FaRegPaperPlane } from "react-icons/fa";
import { TfiInstagram } from "react-icons/tfi";
import hrt from "../assets/images/noun_Heart_682473 (1).svg";
import Tooltip from "../components/tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Share from "../components/Share";
import { IoPaperPlaneOutline } from "react-icons/io5";
import Check from "../components/checkbox";
import ch from "../assets/images/white-heavy-check-mark_2705.png";
import '@djthoms/pretty-checkbox';
import RadioInput from "../components/RadioInput";

const UserProfile = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("Hi, I'd like you to post this!");
  const [disabled, setDisabled] = useState(true);
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [email, setEmail] = useState("");
  const user = useAuth();
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(false);
  const { userName } = useParams();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(true);
  const [ifUser, setIfUser] = useState(false);
  const [learnOpen, setLearnOpen] = useState(false);
  const [successSend, setSuccessSend] = useState(false);
  const [privacySelected, setPrivacySelected] = useState(null);
  const [loadingSend, setLoadingSend] = useState(false);
  const myRef = useRef();
  const [copyText, setSetCopyText] = useState("copy");
  const [shareOpen, setShareOpen] = useState(false);
  const [shareOpen2, setShareOpen2] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [priceStory, setPriceStory] = useState(0);
  const [pricePost, setPricePost] = useState(0);
  const [profPic, setProfPic] = useState(null);

  const checkIfUsernameExists = async () => {
    try {
      setLoading(true);
      if (!(await userNameExists(userName))) {
        // navigation("/notfound")
        setIfUser(false);
      } else setIfUser(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    checkIfUsernameExists();
    fetchPrices();
    fetchProfPic();
  }, []);

  const fetchPrices = async () => {
    try{
      const res = await getUserPrices(userName);
      setPricePost(res.pricePost);
      setPriceStory(res.priceStory);
    } catch(err){
        
    }
  }
  
  const fetchProfPic = async () => {
    try{
      const pP = await getUserProfilePic(userName);
      setProfPic(pP);
    } catch(err){

    }
  }
  useEffect(() => {
    if (
      message &&
      message.trim() !== "" &&
      (image !== null || video !== null) &&
      selected !== null
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [message, image, selected, video]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSend = async () => {
    setSuccessSend(false);
    setPrivacyError(false);
    setError(false);
    setLoadingSend(true);
    if (!email || email.trim() == "" || !selected) {
      setError(true);
      setLoadingSend(false);
      return;
    }
    if (!privacySelected) {
      setPrivacyError(true);
      setLoadingSend(false);
      return;
    }
    if (!validateEmail(email)) {
      setError(true);
      setLoadingSend(false);
      return;
    }
    try {
      if(video){
        const videoUrl = await uploadVideo(video);
        const creatorEmail = await getUserEmail(userName);
        let postTy = "";
        if (selected == "postWall") postTy = "Post to Wall";
        else if (selected == "postStory") postTy = "Post to Story";
        else postTy = "Both";
        await putPendingAds(userName, "", email, postTy, message, videoUrl, "video");
        await sendEmailVideo(email, videoUrl, creatorEmail, postTy, message);
        setSuccessSend(true);
        setLoadingSend(false);
      }
      else if(image){
        const imageUrl = await uploadImage(image);
        const creatorEmail = await getUserEmail(userName);
        let postTy = "";
        if (selected == "postWall") postTy = "Post to Wall";
        else if (selected == "postStory") postTy = "Post to Story";
        else postTy = "Both";
        await putPendingAds(userName, imageUrl, email, postTy, message, "", "image");
        await sendEmail(email, imageUrl, creatorEmail, postTy, message);
        setSuccessSend(true);
        setLoadingSend(false);
      }
    } catch (err) {
      setSuccessSend(false);
      setError(true);
      setLoadingSend(false);
      console.log(err)
    }
  };
  if (loading) {
    return (
      <div className={styles.loading}>
        <Oval
          height={80}
          width={80}
          color="#ffffff"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#ffffff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );
  }
  if (!ifUser) {
    return <PageNotFound />;
  }
  return (
    <>
      <Navbar />
      <Share
        modalIsOpen={shareOpen}
        setIsOpen={setShareOpen}
        url={`spotlyt.app/${userName}`}
      />
      <div className={styles.container}>
        <div className={styles.overlay}></div>
        <div className={styles.topC}>
          <div className={styles.midC}  style={{ display: "flex", display: "flex", alignItems: "center" }}>
            <div className={styles.leftC}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={styles.mainText}>Post to my&#160;</div>
                <img src={insta} className={styles.instaLogo} />.
              </div>
              <div className={styles.secText} style={{ marginBottom: "10px" }}>
                Gain exposure,
              </div>
              <div className={styles.secText} style={{ marginBottom: "10px" }}>
                Expand your reach,
              </div>
              <div className={styles.secText} style={{ marginBottom: "50px" }}>
                Grow your channel or business.
              </div>
              <div className={styles.signupContDesk}>
                <div
                  className={styles.signUpBtn}
                  onClick={() => {
                    myRef.current.scrollIntoView();
                  }}
                >
                  Create a Post
                </div>
                <div
                  className={styles.learnM}
                  onClick={() => setLearnOpen(true)}
                >
                  Learn more
                </div>
              </div>
              <LearnMoreUserProfile
                modalIsOpen={learnOpen}
                setIsOpen={setLearnOpen}
              />
            </div>
            <div className={styles.rightCDesk}>
                <div className={styles.rC}>
                  <div style={{ position: "relative" }}>
                  <div className={styles.profPicCont}>
                    <img src={profPic ? profPic : bulb}/>
                  </div>
                    <img src={hrt} className={styles.hrt} />
                  </div>
                  <div className={styles.transCont}>
                        <a
                          href={`https://instagram.com/${userName}`}
                          target="_blank"
                          style={{ cursor: "pointer" }}
                          className={styles.checkM}
                        >
                          <TfiInstagram style={{fontSize: '1.4vw'}} color="#ffffff" />
                          <div className={styles.usName} style={{marginLeft: '10px'}}>{userName}</div>
                        </a>
                    <div className={styles.urlStuff}>
                      <div className={styles.urlPr}>
                        spotlyt.app/{userName}&#160;&#160;
                      </div>
                      <div className={styles.shareCont}>
                        <div
                          onMouseLeave={() => setSetCopyText("copy")}
                          style={{ marginRight: "10px" }}
                        >
                          <Tooltip text={copyText}>
                            <CopyToClipboard
                              text={`spotlyt.app/${userName}`}
                              onCopy={() => {
                                setSetCopyText("copied");
                              }}
                            >
                              <FaRegCopy style={{ cursor: "pointer" }} />
                            </CopyToClipboard>
                          </Tooltip>
                        </div>

                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => setShareOpen(true)}
                        >
                          <Tooltip text="share">
                            <IoPaperPlaneOutline size={22} />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    href={`https://instagram.com/${userName}`}
                    target="_blank"
                    style={{ cursor: "pointer" }}
                    className={styles.checkM}
                  >
                    {/* <div style={{ marginRight: "15px" }}>Check me out</div> */}
                    {/* <TfiInstagram size={20} color="#ffffff" /> */}
                  </a>
                </div>
              </div>
            <div className={styles.leftCMob}>
            <div className={styles.rightC}>
                  <div className={styles.rC}>
                    <div style={{ position: "relative", marginTop: '100px' }}>
                      <div className={styles.profPicCont}>
                        <img src={profPic ? profPic : bulb} className={styles.bulb} />
                      </div>
                      <img src={hrt} className={styles.hrt} />
                    </div>
                    <div className={styles.transCont}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                          <a
                          href={`https://instagram.com/${userName}`}
                          target="_blank"
                          style={{ cursor: "pointer" }}
                          className={styles.checkM}
                        >
                          <TfiInstagram size={20} color="#ffffff" />
                          <div className={styles.usName} style={{marginLeft: '10px'}}>{userName}</div>
                        </a>
                        
                      </div>
                      <div className={styles.urlStuff}>
                        <div className={styles.urlPr}>
                          spotlyt.app/{userName}&#160;&#160;
                        </div>
                        <div className={styles.shareCont}>
                          <div
                            onMouseLeave={() => setSetCopyText("copy")}
                            style={{ marginRight: "10px" }}
                          >
                            <Tooltip text={copyText}>
                              <CopyToClipboard
                                text={`spotlyt.app/${userName}`}
                                onCopy={() => {
                                  setSetCopyText("copied");
                                }}
                              >
                                <FaRegCopy style={{ cursor: "pointer" }} />
                              </CopyToClipboard>
                            </Tooltip>
                          </div>

                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setShareOpen(true)}
                          >
                            <Share
                              modalIsOpen={shareOpen2}
                              setIsOpen={setShareOpen2}
                              url={`spotlyt.app/${userName}`}
                            />
                            <Tooltip text="share">
                              <IoPaperPlaneOutline size={22} />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div style={{width: '100%', display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                <div className={styles.mainText}>Post to my&#160;</div>
                <img src={insta} className={styles.instaLogo} />.
              </div>
              <div className={styles.mobCont}>
                <div>
                  <div
                    className={styles.secText}
                    style={{ marginBottom: "10px" }}
                  >
                    Gain exposure,
                  </div>
                  <div
                    className={styles.secText}
                    style={{ marginBottom: "10px" }}
                  >
                    Expand your reach,
                  </div>
                  <div
                    className={styles.secText}
                    style={{ marginBottom: "50px" }}
                  >
                    Grow your channel or business.
                  </div>
                </div>
              </div>
            
              <div className={styles.signupContDesk}>
                <div
                  className={styles.signUpBtn}
                  onClick={() => {
                    myRef.current.scrollIntoView();
                  }}
                >
                  Create a Post
                </div>
                <div
                  className={styles.learnM}
                  onClick={() => setLearnOpen(true)}
                >
                  Learn more
                </div>
              </div>
              <LearnMoreUserProfile
                modalIsOpen={learnOpen}
                setIsOpen={setLearnOpen}
              />
            </div>
          </div>
          <div className={styles.signupContMob}>
            <div
              className={styles.signUpBtn}
              onClick={() => {
                myRef.current.scrollIntoView();
              }}
            >
              Create a Post
            </div>
            <div className={styles.learnM} style={{marginTop: '20px'}} onClick={() => setLearnOpen(true)}>
              Learn more
            </div>
          </div>
        </div>
        <div className={styles.secCont} ref={myRef}>
          <DragImage
            image={image}
            setImage={setImage}
            message={message}
            setMessage={setMessage}
            video={video}
            setVideo={setVideo}
          />
          <div className={styles.info}>
            <div className={styles.options}>
              {/* <Radio
                onChange={(e) => {
                  setSelected(e.target.value);
                }}
                name="postType"
                value="postStory"
                shape="curve"
                plain
                icon={
                  selected == "postStory" && <img src={ch} alt="check mark" data-type="image"/>
                }
              >
                Post to my story
              </Radio> */}
              <RadioInput title={"Post to my story"} value={"postStory"} colorBorder={false} isChecked={selected == "postStory"} setSelected={setSelected}/>
              <div className={styles.money}>${priceStory}</div>
            </div>
            <div className={styles.options}>
              {/* <Radio
                onChange={(e) => {
                  setSelected(e.target.value);
                }}
                name="postType"
                value="postWall"
                shape="curve"
                plain
                icon={
                  selected == "postWall" && <img src={ch} className={styles.checkEmj} alt="check mark" />
                }
              >
                {" "}
                Post to my wall
              </Radio> */}
              <RadioInput title={"Post to my wall"} value={"postWall"} colorBorder={false} isChecked={selected == "postWall"} setSelected={setSelected}/>
              <div className={styles.money}>${pricePost}</div>
            </div>
            <div className={styles.options}>
              {/* <Radio
                onChange={(e) => {
                  setSelected(e.target.value);
                }}
                name="postType"
                value="postBoth"
                shape="curve"
                plain
                icon={
                  selected == "postBoth" && <img src={ch} className={styles.checkEmj} alt="check mark" />
                }
              >
                {" "}
                Post to both
              </Radio> */}
              <RadioInput title={"Post to both"} value={"postBoth"} colorBorder={false} isChecked={selected == "postBoth"} setSelected={setSelected}/>
              <div className={styles.money}>${pricePost + priceStory}</div>
            </div>
          </div>

          <div
            className={`${styles.postBtn} ${disabled ? styles.disabled : ""}`}
            onClick={() => setIsOpen(true)}
          >
            Send Post to Creator
          </div>
          <Modal
            isOpen={modalIsOpen}
            style={{
              overlay: {
                backgroundColor: "rgb(0,0,0, 0.7)",
                zIndex: 200,
              },
              content: {
                zIndex: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "none",
                boxSizing: "border-box",
                overflow: "hidden",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "transparent",
              },
            }}
          >
            <div className={styles.modalCont}>
              <div className={styles.close} onClick={() => setIsOpen(false)}>
                <AiFillCloseCircle size={25} />
              </div>
              <div>
                <img
                  src={logo}
                  style={{ height: "40px", marginBottom: "10px" }}
                />
              </div>
              {!successSend ? (
                <div>
                  <div className={styles.subTit}>
                    You're about to create a post with {userName}.&#160;
                  </div>
                  {/* <img src={userPr} style={{width: '15vw', height: 'auto'}}/> */}
                  <div className={styles.ruleCont}>
                    <div className={styles.ruleText}>
                      <span className={styles.finger}>👉</span>
                      They will review your ad/post.
                    </div>
                    <div className={styles.ruleText}>
                      <span className={styles.finger}>👉</span>
                      Stories stay up for 24 hours and wall posts stay up for 30
                      days.
                    </div>
                    <div className={styles.ruleText}>
                      <span className={styles.finger}>👉</span>
                      Once live, all posts are final.
                    </div>
                    <input
                      placeholder="Enter your email"
                      className={styles.inp}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                    />
                    <div className={styles.agree}>
                      <Checkbox
                        onChange={(e) => setPrivacySelected(e.target.value)}
                        name="accept"
                        value="accept"
                        shape="curve"
                        icon={
                          privacySelected == "accept" && (
                            <img src={ch} alt="check mark" />
                          )
                        }
                      >
                      </Checkbox>
                      <span style={{ fontFamily: "proxima", marginLeft: '-8px'}} colorBorder={true}>
                          I accept the <span style={{cursor: 'pointer', color: '#5B94F7'}} onClick={() => window.open('https://app.termly.io/document/privacy-policy/8ff5f29c-ba50-4235-848a-595facfaf87a','_blank','height=600,width=500')}>Privacy Policy</span> and <span style={{cursor: 'pointer', color: '#5B94F7'}} onClick={() => window.open('https://app.termly.io/document/terms-of-service/9eea418b-6431-431f-932e-c8550221d4c2','_blank','height=600,width=500')}>Terms.</span>
                        </span>
                      {/* <RadioInput title={<span style={{ fontFamily: "proxima", marginLeft: '-8px'}} colorBorder={true}>
                          I accept the <span style={{cursor: 'pointer', color: '#5B94F7'}} onClick={() => window.open('https://app.termly.io/document/privacy-policy/8ff5f29c-ba50-4235-848a-595facfaf87a','_blank','height=600,width=500')}>Privacy Policy</span> and <span style={{cursor: 'pointer', color: '#5B94F7'}} onClick={() => window.open('https://app.termly.io/document/terms-of-service/9eea418b-6431-431f-932e-c8550221d4c2','_blank','height=600,width=500')}>Terms.</span>
                        </span>} value={"accept"} isChecked={privacySelected == "accept"} setSelected={() => setPrivacySelected(prev => {
                          if(prev == "accept")
                            return ""
                          return "accept"
                        })}/> */}

                    </div>
                    <div
                      className={styles.postBtn2}
                      onClick={() => handleSend()}
                    >
                      {loadingSend ? (
                        <Oval
                          height={22}
                          width={22}
                          color="#ffffff"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#ffffff"
                          strokeWidth={2}
                          strokeWidthSecondary={2}
                        />
                      ) : (
                        "Send to Creator"
                      )}
                    </div>
                    {error && (
                      <div style={{ color: "#e06666" }}>
                        Please enter a valid email.
                      </div>
                    )}
                    {privacyError && (
                      <div style={{ color: "#e06666" }}>
                        To continue, please accept the privacy policy and terms.
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.confirmCont}>
                  <div className={styles.congText}>
                    Congrats, your post is on its way!
                  </div>
                  {/* <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '2vw'}}>
                           🎊&#160; &#160; 🥳 &#160; &#160;🎉 
                            </div> */}
                  <div style={{position: 'absolute', zIndex: -1}}>

                            <Lottie animationData={cong} loop={true} />
                            </div>
                  <div style={{ marginTop: "20px"}} className={styles.sendText}>
                    If {userName} accepts, you'll receive an email to pay for
                    your post.
                  </div>
                  <div
                    className={styles.postBtn2}
                    onClick={() => {
                      setIsOpen(false);
                      window.location.reload();
                    }}
                  >
                    Got it
                  </div>
                </div>
              )}
            </div>
          </Modal>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default UserProfile;
