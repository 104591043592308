import Modal from 'react-modal';
import { useState } from 'react';
import { AiFillCloseCircle } from "react-icons/ai";
import styles from '../styles/css/Carousel.module.css';
import {sendApproveEmail, sendApproveEmailVideo, addToApproved, deleteAd} from '../service/dataService';
import { useAuth } from '../service/authContext';
import { Oval } from  'react-loader-spinner'
import ch from '../assets/images/white-heavy-check-mark_2705.png';
import dl from '../assets/images/white_download.png';
import { saveAs } from 'file-saver'

const CarouselModal = (props) => {
    const user = useAuth();
    const [loading, setLoading] = useState(false);
    const [approveError, setApproveError] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const handleDownload = (url) => {
        fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            const timestamp = Date.now();
            link.download = `spotlyt_${timestamp}`;
            link.click();
            window.URL.revokeObjectURL(blobUrl);
        })
        .catch(error => console.error('Error downloading file:', error));
      };

    const handleApprove = async () => {
        try {
            setApproveError(false);
            setLoading(true);
            if(!props.canApprove){
                setApproveError(true);
                setLoading(false);
                return;
            }
            if(props.adData.image_url){
                await sendApproveEmail(props.adData.owner, props.adData.image_url, user.displayName, props.adData.type, props.adData.adId);
            } 
            else{
                await sendApproveEmailVideo(props.adData.owner, props.adData.video_url, user.displayName, props.adData.type, props.adData.adId);
            }
           await addToApproved(user.displayName, props.adData.adId);
           await props.switchAd();
           props.setIsOpen(false);
           setLoading(false);
        } catch(err){
            console.log(err);
            setLoading(false)
        }
    }
    const handleDeleteAd = async () => {
        try {
           await deleteAd(user.displayName, props.adData.adId);
           await props.switchAd();
           props.setIsOpen(false);
        } catch(err){
            console.log(err);
        }
    }
    return (
        <div>
        <Modal
                isOpen={props.modalIsOpen}
                style={{
                    overlay: {
                        backgroundColor: 'rgb(0,0,0, 0.7)',
                        zIndex: 200,
                    },
                    content: {
                        zIndex: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 'none',
                        boxSizing: 'border-box',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'transparent'
                    }
                }}
            >
                 <div className={styles.modalCont}>
                    <div className={styles.close} onClick={() => {setLoading(false); setConfirm(false); props.setIsOpen(false)}}>
                            <AiFillCloseCircle size={25}/>
                    </div>
                    <div>
                        {props.adData.image_url && <img src={props.adData.image_url}  className={styles.adImModal}/>}
                        {props.adData.video_url && <video src={props.adData.video_url} controls className={styles.adImModal}/>}
                    </div>
                    <div onClick={() => handleDownload(props.adData.image_url != "" ? props.adData.image_url : props.adData.video_url)} className={styles.signUpBtn}>Download <img className={styles.dl} src={dl}/></div>
                    <div className={styles.infoDiv}>

                    <div style={{marginTop: '20px', marginBottom: '10px'}} className={styles.infText}>Customer Email: <span style={{fontFamily: 'proxima-semi-bold'}}>{props.adData.owner}</span></div>
                    <div>
                    <div className={styles.tagDiv}>
                    {props.adData.type == "Post to Story" ?  <div className={styles.tags}>
                            Post to my Story
                        </div>: props.adData.type == "Post to Wall" ? 
                        <div className={styles.tags}>
                            Post to my Wall
                        </div> :
                        <>
                        <div className={styles.tags} >
                        Post to my Wall
                    </div>
                    <div className={styles.tags} style={{marginLeft: '10px'}}>
                    Post to my Story
                </div></>
                        }
                    </div>
                    {props.adData.message && <div>
                        Message: {props.adData.message}
                        </div>}
                    </div>
                    </div>
                    { props.adData.pending &&
                    <>
                    <div className={styles.approveBtn} onClick={() => {handleApprove()}}>
                    {loading ?  <Oval
                height={25}
                width={25}
                color="white"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                strokeWidth={2}
                strokeWidthSecondary={2}
                secondaryColor="#ffffff"

            />: "Approve to Post"}
                    </div>
                    {!confirm && <div style={{cursor: 'pointer'}} onClick={() => setConfirm(true)} className={styles.reject}>
                        Reject this post
                    </div>}
                    {confirm && <div style={{cursor: 'pointer'}} onClick={() => handleDeleteAd()} className={styles.reject}>Confirm?</div>}
                    </>
                    }
                    {approveError && <div style={{color: "#e06666", marginTop: '10px'}}>Please set up a payment method to approve an ad.</div>}
                    {!props.adData.pending && <div style={{width: '100%', display: 'flex', marginTop: '10px'}}>{props.adData.is_paid ? 
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Paid: <img src={ch} className={styles.chek}/></div>
                    : `📩 You will receive an email confirmation when your customer has approved and paid.`}</div>}
                 </div>
            </Modal>
        </div>
    )
}
export default CarouselModal;