import styles from '../styles/css/ResetPassword.module.css';
import eLogo from '../assets/images/email_icon.png';
import { BsFillPersonFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiFillInstagram } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../service/authContext';
import { useEffect } from 'react';
import { useState } from 'react';
import {logInWithEmailAndPassword} from '../service/firebase';
import Navbar from '../components/navbar';
import logo from '../assets/images/spotlyt_logo.png';
import { AiFillLock } from "react-icons/ai";
import water from '../assets/images/spotlyt_wordmark_gradient.png';
import { Oval } from  'react-loader-spinner'
import {hanldeResetPassword} from '../service/firebase';
import greenC from '../assets/images/greenC.png';

const ResetPassword = (props) => {
    const navigation = useNavigate();
    const user = useAuth();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [credError, setCredError] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const [isVerified, setIsVerified] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    async function startVerification() {
        try{
            setLoading(true);
            const oobCode = props.actionCode;
            setCredError(false);
            setPasswordError(false);

            if(password.trim() !== confirmPassword.trim()){
                setCredError(true);
                setLoading(false);
                return;
            }
            if (password.length < 6) {
                setPasswordError(true);
                setLoading(false);
                return false;
              }
            await hanldeResetPassword(oobCode, password);
            setIsVerified(true);
        } catch(err){
            setIsVerified(false);
            console.log(err);
            setLoading(false);
        }
    }
    // useEffect(() => {
    //     startVerification();
    // }, [])


    return (
        <div className={styles.container}>
            <Navbar/>
        <div className={styles.topC}>
        {!isVerified ? <div>
            <div className={styles.abs}>
            <img src={logo} className={styles.lgo}/>

                <div className={styles.formCSignIn}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '8px'}}>
                        <img src={water} style={{height: '40px'}}/>
                        </div>
                    <div className={styles.innerF}>
                        <div style={{display: 'flex' , justifyContent: 'center', alignItems: 'center'}}>
                        <AiFillLock size={20} color="#999999"/>
                            <input className={styles.inp} placeholder='New password' style={{width: '100%', marginLeft: '5px'}} type='password' onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div style={{display: 'flex' , justifyContent: 'center', alignItems: 'center'}}>
                        <AiFillLock size={20} color="#999999"/>
                            <input className={styles.inp} placeholder='Confirm password' style={{width: '100%', marginLeft: '5px'}} type='password' onChange={(e) => setConfirmPassword(e.target.value)}/>
                        </div>
                            <div className={styles.signUpBtn} onClick={() => startVerification()}>
                                {loading ?  <Oval
                                                height={30}
                                                width={30}
                                                color="white"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                                ariaLabel='oval-loading'
                                                strokeWidth={2}
                                                strokeWidthSecondary={2}
                                                secondaryColor="#ffffff"
                                            />: "Update Password"}
                            </div>
                    </div>
                    {credError && <div style={{color: '#e06666'}}>Passwords don't match.</div>}
                    {passwordError && (
                    <div style={{ color: "#e06666" }}>
                        Passwords must be at least 6 characters.
                    </div>
                    )}
                </div>
            </div>

        </div>: 
        <div className={styles.abs}>
        <div className={styles.box}>
        <img src={greenC} className={styles.greenC}/>
        <div className={styles.stat}>
            Your password has been changed successfuly.
        </div>
        <div className={styles.thankText}>
            You can continue using Spotlyt.
        </div>
        <div className={styles.signUpBtn} onClick={() => window.location.href = `https://www.spotlyt.app/signin`}>
            Sign in
        </div>
</div>
</div>}
        </div>
    </div>
    )
}
export default ResetPassword;