import {
    PaymentElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import styles from '../styles/css/Checkout.module.css';
import { useState, useEffect } from 'react';
import { Oval } from  'react-loader-spinner'

const CheckoutForm = (props) => {
    const elements = useElements();
    const stripe = useStripe();
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const submitHandler = async () => {
        try {
          setLoading(true);
          if (!stripe || !elements || props.customerEmail.trim() == "") {
            return;
          }
    
          const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: `http://www.spotlyt.app/complete/${props.uid}/${props.userName}/${props.customerEmail}/${props.adPrice}`,
            },
          });
    
          if (result.error) {
            setErrorMessage(result.error.message);
            setLoading(false);
            console.log(result.error.message);
          } else {
            // customer will be redirected to `return_url`.
          }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
      };
  return (
    <form>
      <PaymentElement />
      <div className={styles.signUpBtn} onClick={submitHandler} disabled={!stripe}>
      {loading ?  
      <Oval
        height={22}
        width={22}
        color="#ffffff"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#ffffff"
        strokeWidth={2}
        strokeWidthSecondary={2}
      /> : 'Submit'}
        </div>
        {errorMessage && <div>{errorMessage}</div>}

    </form>
  );
};

export default CheckoutForm;