import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    signOut,
    EmailAuthProvider,
    updatePassword,
    reauthenticateWithCredential,
    createUserWithEmailAndPassword,
    deleteUser,
    sendPasswordResetEmail,
    sendEmailVerification,
    updateProfile,
    applyActionCode,
    verifyPasswordResetCode, 
    confirmPasswordReset
  } from "firebase/auth";
  import { initializeAuth } from 'firebase/auth';

  const firebaseConfig = {
    apiKey: "AIzaSyCFcvthaOxWaSmH_eu6FD-pidxT8jrLHHw",
    authDomain: "spotlyt-12782.firebaseapp.com",
    projectId: "spotlyt-12782",
    storageBucket: "spotlyt-12782.appspot.com",
    messagingSenderId: "787422738928",
    appId: "1:787422738928:web:b8a529b08799ffa2dac535"
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth();

const logInWithEmailAndPassword = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential.user;
    } catch (err) {
        throw err;
    }
  };
  const createWithEmailAndPassword = async (email, password, instagramUserName) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await updateProfile(user, {
        displayName: instagramUserName
      })
      return userCredential.user;
    } catch (err) {
        throw err;
    }
  };

  const updateDisplayName = async (name) => {
    try{
      const user = auth.currentUser;
      await updateProfile(user, {
        displayName: name
      })
    } catch(err){
        throw err;
    }
  }
  const signOutUser = async () => {
    try {
      await signOut(auth);
    } catch(err){
        throw err;
    }
  }

  const changePassword = async (currentPassword, newPassword) => {
    try{
      const user = auth.currentUser;
      var credentials = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
        await reauthenticateWithCredential(user, credentials)
        await updatePassword(user, newPassword);
    } catch(err){
        throw err;
    }
  }
  const delUser = async (currentPassword) => {
    try{ 
      const user = auth.currentUser;
      var credentials = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
        await reauthenticateWithCredential(user, credentials)
      await deleteUser(user)
    } catch(err){
        throw err;
    }
  }
  const resetPassword = async (email) => {
    try{
      await sendPasswordResetEmail(auth, email);
    } catch(err){
        throw err;
    }
  }

  const checkUserStatus = () => {
    try{
      return auth.currentUser != null;
    } catch(err){
        throw err;
    }
  }
  
  const verifyEmail = async () => {
    try{
      await sendEmailVerification(auth.currentUser);
    } catch(err){
        throw err;
    }
  }
  const isEmailVerified = () => {
    try{
      return auth.currentUser.emailVerified;
    } catch(err){
        throw err;
    }
  }
 const handleVerifyEmail = async (actionCode) => {
    try {
      await applyActionCode(auth, actionCode);
    } catch(err){
        throw err;
    }
  }
const hanldeResetPassword = async (actionCode, password) => {
    try {
      const email = await verifyPasswordResetCode(auth, actionCode);
      await confirmPasswordReset(auth, actionCode, password);
    } catch(err){
        throw err;
    }
  }
  export {
    logInWithEmailAndPassword,
    createWithEmailAndPassword,
    app,
    auth,
    signOutUser,
    changePassword,
    delUser,
    checkUserStatus,
    isEmailVerified,
    resetPassword,
    verifyEmail,
    updateDisplayName,
    handleVerifyEmail,
    hanldeResetPassword
  }