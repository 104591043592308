import styles from '../styles/css/Footer.module.css';
import logo from '../assets/images/footer_mark.png';
import amex from '../assets/images/amex.png';
import dis from '../assets/images/discover.png';
import visa from '../assets/images/visa.png';
import paypal from '../assets/images/paypal.png';
import master from '../assets/images/mastercard.png';
import { AiFillInstagram } from "react-icons/ai";
import { MdEmail } from "react-icons/md";

const Footer = () => {
    return (
        <div className={styles.container}>
            <div className={styles.leftC}>
                <div className={styles.lHelp}>
                <img src={logo} className={styles.lgo}/>
                    <div className={styles.text}>
                        A poweful tool for
                    </div>
                    <div className={styles.text} style={{marginTop: '2px'}}>
                        content creators.
                    </div>
                    <div className={styles.textMob}>
                        A poweful tool for content creators.
                    </div>
                </div>
                <div className={styles.cpyR1}>
                © 2024 Spotlyt LLC. &#160;All rights reserved.
                </div>
            </div>
            <div className={styles.rightC}>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div className={styles.abt} style={{marginRight: '30px'}}>
                        <div style={{marginBottom: '10px', cursor: 'pointer'}} onClick={() => window.open('https://app.termly.io/document/privacy-policy/8ff5f29c-ba50-4235-848a-595facfaf87a','_blank','height=600,width=500')}>Privacy Policy</div>
                        
                        <div style={{marginBottom: '10px', cursor: 'pointer'}} onClick={() => {
                            window.open('https://app.termly.io/document/terms-of-service/9eea418b-6431-431f-932e-c8550221d4c2','_blank','height=600,width=500, title=Spotlyt');
                        }
                            }>Terms of Service</div>
                    </div>
                    <div className={styles.social}>
                        <a style={{display: 'flex', alignItems: 'center', marginBottom: '10px', cursor: 'pointer'}} href='https://www.instagram.com/spotlyt.app/' target="_blank">
                            <AiFillInstagram size={22} style={{marginRight: '5px'}}/>
                            <div style={{}}>Instagram</div>
                        </a>
                        <a style={{display: 'flex', alignItems: 'center', marginBottom: '10px', cursor: 'pointer'}} onClick={() => {
                        window.open('mailto:team@spotlyt.app?subject=I have a question about Spotlyt')
                        }}>
                        <MdEmail size={20} style={{marginRight: '5px'}}/>
                        <div>Contact</div>
                        </a>
                    </div>
                </div>
                <div className={styles.desCards}>
                    <div className={styles.cards}>
                        <img src={visa} style={{height: '40px', width: 'auto'}}/>
                        <img src={master} style={{height: '40px', width: 'auto'}}/>
                        <img src={dis} style={{height: '40px', width: 'auto'}}/>
                        <img src={amex} style={{height: '40px', width: 'auto'}}/>
                        <img src={paypal} style={{height: '40px', width: 'auto'}}/>
                    </div>
                    <div className={styles.cpyR2}>
                    © 2024 Spotlyt LLC. &#160;All rights reserved.
                    </div>
                </div>

                <div className={styles.mobCards}>
                    <div className={styles.cards}>
                        <img src={visa} style={{height: '40px', width: 'auto'}}/>
                        <img src={master} style={{height: '40px', width: 'auto'}}/>
                        <img src={dis} style={{height: '40px', width: 'auto'}}/>
                        <img src={amex} style={{height: '40px', width: 'auto'}}/>
                        <img src={paypal} style={{height: '40px', width: 'auto'}}/>
                    </div>
                    <div className={styles.cpyR2}>
                    © 2024 Spotlyt LLC. &#160;All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;