import Modal from "react-modal";
import { AiFillCloseCircle } from "react-icons/ai";
import styles from "../styles/css/LearnModal.module.css";

const ProposalLearnMore = (props) => {
  return (
    <Modal
      isOpen={props.modalIsOpen}
      style={{
        overlay: {
          zIndex: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgb(0,0,0, 0.7)",
        },
        content: {
          zIndex: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          boxSizing: "border-box",
          overflow: "hidden",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "transparent",
        },
      }}
    >
      <div className={styles.modalCont}>
        <div className={styles.titles} style={{ color: "white" }}>
          Propose an Ad or Collaboration
        </div>
        <div className={styles.pText}>
          You can propose your own ads or collaborations with businesses and
          channels. &#160;Upload an image, add a message, and send it for
          approval. &#160;When the recipient has approved and paid for the post,
          you have 48 hours to put it up on your instagram channel.
          &#160;Proposing posts is a great way to earn money with Spotlyt.
        </div>
        <div
          className={styles.signUpBtn}
          onClick={() => props.setIsOpen(false)}
        >
          Got it
        </div>
      </div>
    </Modal>
  );
};
export default ProposalLearnMore;
