import Navbar from "../components/navbar";

const PageNotFound = () => {
    return (
        <div>
            <Navbar/>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <div>Sorry, this page isn't available.</div>
            </div>
        </div>
    )
}
export default PageNotFound;