import styles from '../styles/css/Signup.module.css';
import eLogo from '../assets/images/email_icon.png';
import { BsFillPersonFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiFillInstagram } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../service/authContext';
import { useEffect } from 'react';
import { useState } from 'react';
import {resetPassword} from '../service/firebase';
import Navbar from '../components/navbar';
import logo from '../assets/images/spotlyt_logo.png';
import { AiFillLock } from "react-icons/ai";
import water from '../assets/images/spotlyt_wordmark_gradient.png';
import { Oval } from  'react-loader-spinner'
import {sendResetPasswordEmail} from '../service/dataService';

const ForgotPassword = () => {
    const navigation = useNavigate();
    const user = useAuth();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccses] = useState(false);
    const [error, setError] = useState(false);

    const handleForgot = async () => {
        try{
            setLoading(true);
            setSuccses(false);
            setError(false);
            if(email.trim() == ""){
                setError(true);
                setLoading(false);
                return;
            }
            await sendResetPasswordEmail(email);
            setSuccses(true);
        } catch(err){
            setSuccses(false);
            setLoading(false);
        }
    }
    useEffect(() => {
        if(user)
            navigation("/dashboard")
    }, [])

    return (
        <div className={styles.container}>
            <Navbar/>
        <div className={styles.topC}>
            <div className={styles.abs}>
            <img src={logo} className={styles.lgo}/>

                <div className={styles.formCSignIn}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div className={styles.welc}>Reset your Password</div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '8px'}}>
                        <img src={water} style={{height: '40px'}}/>
                        </div>
                    {!success ? <div className={styles.innerF}>
                        <div style={{display: 'flex' , justifyContent: 'center', alignItems: 'center'}}>
                            <MdEmail size={20} color="#999999"/>
                            <input className={styles.inp} placeholder='Email' style={{width: '100%', marginLeft: '5px'}} type='email' onChange={(e) => setEmail(e.target.value)}/>

                        </div>
                            <div className={styles.signUpBtn} onClick={() => handleForgot()}>
                                {loading ?  <Oval
                                                height={30}
                                                width={30}
                                                color="white"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                                ariaLabel='oval-loading'
                                                strokeWidth={2}
                                                strokeWidthSecondary={2}
                                                secondaryColor="#ffffff"

                            />: "Reset Password"}
                            </div>
                            {error && <div style={{color: '#e06666'}}>Please enter an email address.</div>}

                    </div> : 
                    <div>
                    <div style={{marginTop: '50px'}}>
                        If this account exists, you will receive an email to reset your password.
                    </div>
                    <div className={styles.signUpBtn} onClick={() => navigation("/signin")}>
                                Got it
                            </div>
                    </div>
                    }
                </div>
                {/* <div style={{display: 'flex' , justifyContent: 'center', alignItems: 'center', paddingTop: '10px'}}>
                        <span style={{opacity: '0.6', color: 'white'}}>Go back to</span> <span style={{marginLeft: '5px', cursor: 'pointer', fontFamily: 'proxima-semi-bold', color: 'white'}} onClick={() => {
                            navigation("/signin")
                        }}> Sign in</span>
                    </div> */}
            </div>

        </div>
    </div>
    )
}
export default ForgotPassword;