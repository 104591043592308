import React, { useState } from 'react';
import styles from '../styles/css/Tooltip.module.css';

const Tooltip = ({children, text}) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);

    const showTooltip = () => {
      setTooltipVisible(true);
    };
  
    const hideTooltip = () => {
      setTooltipVisible(false);
    };
    return (
        <div className={styles.tooltipContainer} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        {children}
        {isTooltipVisible && <div className={styles.tooltip}>{text}</div>}
      </div>
    )
}
export default Tooltip