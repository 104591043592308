import { Link } from 'react-router-dom';
import logo from '../assets/images/spotlyt_wordmark_gradient.png';
import styles from '../styles/css/Navbar.module.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../service/authContext';
import {signOutUser} from '../service/firebase';
import { useEffect, useRef, useState } from "react";
import LearnMoreUserProfile from './learnMoreUserProfile';

let prevScrollpos = window.pageYOffset;

const PayNav = (props) => {
    const navigate = useNavigate();
    const user = useAuth();

    const wrRef = useRef();
    const [learnOpen, setLearnOpen] = useState(false);


    return (
        <div ref={wrRef} className={styles.container}>
            <LearnMoreUserProfile modalIsOpen={learnOpen} setIsOpen={setLearnOpen}/>

        <div className={styles.leftDiv} onClick={() => navigate("/")}>
            
            <div className={styles.navText}>
                <img src={logo} className={styles.lgo}/>
            </div>
        </div>
        <div className={styles.rightDiv}>
            <div className={styles.loginBtn} onClick={async () => {
                setLearnOpen(true);
            }}>
                Learn More
            </div>
        </div>
    </div>
    )
}
export default PayNav;