import React, { useState, useRef, useEffect } from "react";
import styles from "../styles/css/Drag.module.css";
import sv from "../assets/images/pppointed.svg";
import { AiFillCloseCircle } from "react-icons/ai";
import im from "../assets/images/image_icon.png";
import VideoErrModal from "./videoErrModal";

const DragImage = (props) => {
  const inputFile = useRef(null);
  const [imageToShow, setImageToShow] = useState(null);
  const [videoToShow, setVideoToShow] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [vidDur, setVidDur] = useState(0);

  useEffect(() => {
    if(vidDur > 59){
      setImageToShow(null);
      setVideoToShow(null);
      props.setImage(null);
      props.setVideo(null);
    }
  }, [vidDur])

  const videoEl = useRef(null);

  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    if (!video) return;
    setVidDur(video.duration);
    if(video.duration > 59){
      setModalIsOpen(true);
    }
  };

  const tapUpload = () => {
    inputFile.current.click();
  };

  const handleTapUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const imageUrl = URL.createObjectURL(file);
      setImageToShow(imageUrl);
      props.setImage(file);
    } else if (file.type.startsWith("video/")) {
        const videoUrl = URL.createObjectURL(file);
        setVideoToShow(videoUrl);
        props.setVideo(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      const imageUrl = URL.createObjectURL(file);
      setImageToShow(imageUrl);
      props.setImage(file);
    } else if (file.type.startsWith("video/")) {
        const videoUrl = URL.createObjectURL(file);
        setVideoToShow(videoUrl);
        props.setVideo(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleImageReset = () => {
    URL.revokeObjectURL(props.image);
    props.setImage(null);
    setImageToShow(null);
  };

  return (
    <div className={styles.topCC}>
      <VideoErrModal modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen}/>
      <div className={styles.titleText}>
        Create your post here&#160; &#160;<span className={styles.emjArr}>⤵️</span>
        {/* <div className={styles.abs}>
          <img src={sv} className={styles.arr}/>
        </div> */}
      </div>
      <div className={styles.polCont}>
        <div className={styles.whiteCont}>
          {imageToShow ? (
            <div
            className={styles.imageToShowCont}>
              <div
                className={styles.close}
                onClick={() => setImageToShow(null)}
              >
                <AiFillCloseCircle size={25} />
              </div>
              <img src={imageToShow} alt="img" className={styles.imgToShow} />
            </div>
          ) : videoToShow ? (
            <div
            className={styles.imageToShowCont}>
              <div
                className={styles.close}
                onClick={() => {
                  setImageToShow(null);
                  setVideoToShow(null);
                }}
              >
                <AiFillCloseCircle size={25} />
              </div>
              <video
                  src={videoToShow}
                  controls
                  onLoadedMetadata={handleLoadedMetadata}
                  ref={videoEl}
                  className={styles.imgToShow}
                />
            </div>
          ) : (
            <div className={styles.bCont}>
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                style={{
                  width: "100%",
                  height: "80%",
                  border: "1px dashed #eeeeee",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "proxima",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  borderRadius: "5px",
                }}
              >
                <img src={im} className={styles.imPlace} />
                <p style={{ color: "white" }}>Drag an image or video here</p>
                <div
                  className={styles.postBtn}
                  onClick={() => {
                    tapUpload();
                  }}
                >
                  Tap to upload
                </div>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={handleTapUpload}
                  accept="image/*,video/*"
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.txtWrapper}>
          <textarea
            value={props.message}
            onChange={(e) => props.setMessage(e.target.value)}
            rows={3}
            placeholder="Type a message here..."
            className={styles.inp}
            maxlength="200"
          />
        </div>
      </div>
    </div>
  );
};

export default DragImage;
