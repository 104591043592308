import styles from '../styles/css/Signup.module.css';
import eLogo from '../assets/images/email_icon.png';
import { BsFillPersonFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiFillInstagram } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../service/authContext';
import { useEffect } from 'react';
import { useState } from 'react';
import {logInWithEmailAndPassword} from '../service/firebase';
import Navbar from '../components/navbar';
import logo from '../assets/images/spotlyt_logo.png';
import { AiFillLock } from "react-icons/ai";
import water from '../assets/images/spotlyt_wordmark_gradient.png';
import { Oval } from  'react-loader-spinner'

const Signin = () => {
    const navigation = useNavigate();
    const user = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [credError, setCredError] = useState(false);

    useEffect(() => {
        if(user)
            navigation("/dashboard")
    }, [user])

    const handleNext = async () => {
        try{
            setCredError(false);
            await logInWithEmailAndPassword(email, password);
            navigation("/dashboard");
            setCredError(false);
        } catch(err){
            setCredError(true);
        }
    }
    return (
        <div className={styles.container}>
            <Navbar/>
        <div className={styles.topC}>
            <div className={styles.abs}>
            <img src={logo} className={styles.lgo}/>

                <div className={styles.formCSignIn}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div className={styles.welc}>Welcome back!</div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '8px'}}>
                        <img src={water} style={{height: '40px'}}/>
                        </div>
                    <div className={styles.innerF}>
                        <div style={{display: 'flex' , justifyContent: 'center', alignItems: 'center'}}>
                            <MdEmail size={20} color="#999999"/>
                            <input className={styles.inp} placeholder='Email' style={{width: '100%', marginLeft: '5px'}} type='email' onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <div style={{display: 'flex' , justifyContent: 'center', alignItems: 'center'}}>
                        <AiFillLock size={20} color="#999999"/>
                            <input className={styles.inp} placeholder='Password' style={{width: '100%', marginLeft: '5px'}} type='password' onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                            <div className={styles.signUpBtn} onClick={() => handleNext()}>
                                {loading ?  <Oval
                                                height={30}
                                                width={30}
                                                color="white"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                                ariaLabel='oval-loading'
                                                strokeWidth={2}
                                                strokeWidthSecondary={2}
                                                secondaryColor="#ffffff"

                                            />: "Sign in"}
                            </div>
                    </div>
                    {credError && <div style={{color: '#e06666'}}>Please check your email or password.</div>}
                    <div className={styles.needAcc}>
                        <span style={{opacity: '0.6'}}>Need an account?</span> <span style={{marginLeft: '5px', cursor: 'pointer', fontFamily: 'proxima-semi-bold'}} onClick={() => {
                            navigation("/signup")
                        }}> Sign up</span>
                    </div>
                    <div style={{display: 'flex' , justifyContent: 'center', alignItems: 'center',}}>
                        <div onClick={() => {
                            navigation("/forgotPassword")
                        }}
                        className={styles.forgotText}
                        style={{ }}>Forgot password?</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    )
}
export default Signin;