import styles from '../styles/css/SetPrices.module.css';
import {updatePrices} from '../service/dataService';
import { useState } from 'react';
import { useAuth } from '../service/authContext';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navbar';
import vid from '../assets/images/spotlyt_landing_background.mp4';
import logo from '../assets/images/spotlyt_logo.png';

const SetPrices = () => {
    const [priceStory, setPriceStory] = useState(25);
    const [pricePost, setPricePost] = useState(25);
    const [error, setError] = useState(false);
    const user = useAuth();
    const navigation = useNavigate();

    const handleNext = async () => {
        try{
            setError(false);
            if(pricePost < 25 || priceStory < 25 || pricePost > 2500 || priceStory > 2500){
                setError(true);
                return;
            }
            await updatePrices(user.displayName, priceStory, pricePost);
            navigation("/dashboard");
        } catch(err){
            setError(true);
        }
    }
    return (
        <div className={styles.container}>
            <Navbar/>

        <div className={styles.topC}>
        <div className={styles.overlay}>
                {/* <video autoPlay loop muted>
                    <source src={vid} />
                </video> */}
            </div>
            <div className={styles.abs}>
            <img src={logo} className={styles.lgo}/>

                <div className={styles.formC}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div className={styles.welc}>Price Your Posts &#160;🏷️</div>
                    </div>
                    <div className={styles.innerF}>
                        <div style={{marginTop: '5px'}}>
                            <div className={styles.priceText}>
                                Price per Story (between $25 and $2500)
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    $
                                <input className={styles.inp} type="number" onChange={(e) => setPriceStory(e.target.value)} value={priceStory}/>
                            </div>
                        </div>
                        <div style={{marginTop: '20px'}}>
                            <div className={styles.priceText}>
                                Price per Post (between $25 and $2500)
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    $
                                <input className={styles.inp} type="number" onChange={(e) => setPricePost(e.target.value)} value={pricePost}/>
                            </div>
                        </div>
                        <div className={styles.signUpBtn} onClick={() => handleNext()}>
                                    All Done
                        </div>
                        {error && <div style={{ color: "#e06666", marginTop: "10px" }}>
                      Please set a price between $25 and $2500.
                    </div>}
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', color: '#5b5b5b', cursor: 'pointer'}} className={styles.skipF}
                        onClick={() => {
                            handleNext()
                        }}
                        >Skip for now</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    )
}
export default SetPrices;