import Modal from "react-modal";
import { AiFillCloseCircle } from "react-icons/ai";
import styles from "../styles/css/ProposalModal.module.css";
import { useState, useRef, useEffect } from "react";
import im from "../assets/images/image_icon.png";
import { sendEmail, putPendingAds, sendApproveEmail, putApprovedAds } from "../service/dataService";
import { useAuth } from "../service/authContext";
import { uploadImage, uploadVideo } from "../service/dataService";
import { Checkbox, Radio, Switch } from 'pretty-checkbox-react';
import { Oval } from  'react-loader-spinner'
import ch from '../assets/images/white-heavy-check-mark_2705.png';
import VideoErrModal from "./videoErrModal";
import RadioInput from "./RadioInput";

const SendAdProposal = (props) => {
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [cusEmail, setCusEmail] = useState("");
  const inputFile = useRef(null);
  const [imageToShow, setImageToShow] = useState(null);
  const [videoToShow, setVideoToShow] = useState(null);
  const [loadingSend, setLoadingSend] = useState(false);
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState(null);
  const [successSend, setSuccessSend] = useState(false);
  const user = useAuth();
  const [privacyError, setPrivacyError] = useState(false);
  const [paySetupError, setPaySetupError] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [vidDur, setVidDur] = useState(0);

  useEffect(() => {
    if(vidDur > 59){
      setImageToShow(null);
      setVideoToShow(null);
      setImage(null);
      setVideo(null);
    }
  }, [vidDur])

  const videoEl = useRef(null);

  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    if (!video) return;
    setVidDur(video.duration);
    if(video.duration > 59){
      setModalIsOpen(true);
    }
  };

  const tapUpload = () => {
    inputFile.current.click();
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleTapUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const imageUrl = URL.createObjectURL(file);
      setImageToShow(imageUrl);
      setImage(file);
    } else if (file.type.startsWith("video/")) {
      const videoUrl = URL.createObjectURL(file);
      setVideoToShow(videoUrl);
      setVideo(file);
  }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      const imageUrl = URL.createObjectURL(file);
      setImageToShow(imageUrl);
      setImage(file);
    } else if (file.type.startsWith("video/")) {
      const videoUrl = URL.createObjectURL(file);
      setVideoToShow(videoUrl);
      setVideo(file);
  }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };
  const handleSend = async () => {
    setSuccessSend(false);
    setLoadingSend(true);
    setPrivacyError(false);
    setError(false);
    setPaySetupError(false);
    if (!cusEmail || cusEmail.trim() == "" || (!image && !video) || message.trim() == "") {
      setError(true);
      setLoadingSend(false);
      return;
    }
    if(!validateEmail(cusEmail)){
      setError(true);
      setLoadingSend(false);
      return;
    }
    if (!selected) {
      setPrivacyError(true);
      setLoadingSend(false);
      return;
    }
    if(!props.canApprove) {
      setPaySetupError(true);
      setLoadingSend(false);
      return;
    }
    try {
      if(video){
        const videoUrl = await uploadVideo(video);
        let postTy = "";
        if (selected == "postWall") postTy = "Post to Wall";
        else if (selected == "postStory") postTy = "Post to Story";
        else postTy = "Both";
        const adId = await putApprovedAds(user.displayName, "", cusEmail, postTy, videoUrl, "video");
       // await sendApproveEmail(cusEmail, imageUrl, user.displayName, postTy, adId);
        setSuccessSend(true);
        setLoadingSend(false);
      }
      else if(image){
        const imageUrl = await uploadImage(image);
        // const creatorEmail = await getUserEmail(userName);
        let postTy = "";
        if (selected == "postWall") postTy = "Post to Wall";
        else if (selected == "postStory") postTy = "Posy to Story";
        else postTy = "Both";
       // await putPendingAds(user.displayName, imageUrl, cusEmail, postTy);
       const adId = await putApprovedAds(user.displayName, imageUrl, cusEmail, postTy, "", "image");
       await sendApproveEmail(cusEmail, imageUrl, user.displayName, postTy, adId);
  
        setSuccessSend(true);
        setLoadingSend(false);
      }
    } catch (err) {
      console.log(err);
      setSuccessSend(false);
      setError(true);
      setLoadingSend(false);
      setPaySetupError(false);
    }
  };
  return (
    <Modal
      isOpen={props.modalIsOpen}
      style={{
        overlay: {
          zIndex: 200,
          backgroundColor: 'rgb(0,0,0, 0.7)',
        },
        content: {
          zIndex: 200,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'none',
          boxSizing: 'border-box',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent'
      }
      }}
    >
      <div className={styles.modalCont} style={{height: successSend ? 'auto': '100%'}}>
      <VideoErrModal modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen}/>
        <div className={styles.close} onClick={() => props.setIsOpen(false)}>
          <AiFillCloseCircle size={26} />
        </div>
        { !successSend ?
          <div className={styles.initialWrap}>
        <div className={styles.titles}>
          Propose an Ad or Collaboration
        </div>
        <div className={styles.polCont}>
          <div className={styles.whiteCont}>
            {imageToShow ? (
              <div className={styles.imageToShowCont}>
                <div
                  className={styles.close}
                  onClick={
                    () => {
                      setImageToShow(null);
                      setImage(null);
                    }
                  }
                >
                  <AiFillCloseCircle size={25} />
                </div>
                  <img src={imageToShow} alt="img" className={styles.imgToShow} />
              </div>
            ) : videoToShow ? (
              <div
            className={styles.imageToShowCont}>
              <div
                className={styles.close}
                onClick={() => {
                  setVideo(null);
                  setVideoToShow(null);
                }}
              >
                <AiFillCloseCircle size={25} />
              </div>
              <video
                  src={videoToShow}
                  controls
                  onLoadedMetadata={handleLoadedMetadata}
                  ref={videoEl}
                  className={styles.imgToShow}
                />
            </div>
            ) : (
              <div className={styles.bCont}>
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragEnter={handleDragEnter}
                  style={{
                    width: "100%",
                    height: "80%",
                    border: "1px dashed #eeeeee",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "proxima",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    borderRadius: "5px",
                  }}
                >
                  <img src={im} className={styles.imPlace} />
                  <p style={{ color: "white" }}>Drag an image or video here</p>
                  <div
                    className={styles.tapBtn}
                    onClick={() => {
                      tapUpload();
                    }}
                  >
                    Tap to upload
                  </div>
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={handleTapUpload}
                    accept="image/*,video/*"
                  />
                </div>
              </div>
            )}
          </div>

          <div className={styles.txtWrapper}>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={3}
              placeholder="Type a message here..."
              className={styles.inp}
              maxlength="200"
            />
          </div>
        </div>
        <div className={styles.postType}>
        <Radio onChange={(e) => {setSelected(e.target.value)}} name='postType' value="postStory" shape="curve" icon={
                          selected == "postStory" && <img
                          src={ch}
                          alt="check mark"
                      />
                      }>Post to story</Radio>
        <Radio onChange={(e) => {setSelected(e.target.value)}} name='postType' value="postWall" shape="curve" icon={
                          selected == "postWall" && <img
                          src={ch}
                          alt="check mark"
                      />
                      }>Post to Wall</Radio>
        <Radio onChange={(e) => {setSelected(e.target.value)}} name='postType' value="Both" shape="curve"icon={
                          selected == "Both" && <img
                          src={ch}
                          alt="check mark"
                      />
                      }>Both</Radio>
        </div>
        <input
          placeholder="Customer email"
          className={styles.inpEmail}
          required
          onChange={(e) => setCusEmail(e.target.value)}
          type="email"
        />

        <div className={styles.postBtn} onClick={() => handleSend()}>
          {loadingSend ? <Oval
                height={25}
                width={25}
                color="#ffffff"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#ffffff"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />: "Send to Customer"}
        </div>
        {error && (
                      <div style={{ color: "#e06666", width: '100%'}}>
                        Please enter a valid email.
                      </div>
                    )}
        {privacyError && (
                      <div style={{ color: "#e06666", width: '100%' }}>
                        Please accept the privacy policy and terms.
                      </div>
        )}
        {paySetupError && (
            <div style={{ color: "#e06666", width: '100%'}}>
              Please setup a payment method.
          </div>
        )}
        </div> :
        <div className={styles.congCont}>
          <div className={styles.titles2}>Your proposal has been sent</div>

          <div className={styles.bulletText}>
            Once your customer pays, you'll receive an email notifying you to post the content.
          </div>

          <div className={styles.ruleCont}>
                        <div className={styles.ruleText}>
                            <span style={{fontSize: '2vw', marginRight: '10px'}}>👉</span> Once paid you have 48 hours to post the content.
                        </div>
                        <div className={styles.ruleText}>
                            <span style={{fontSize: '2vw', marginRight: '10px'}}>👉</span>Remember, stories must stay up for 24 hours and wall posts must stay up for seven (7) days.
                        </div>
                        <div className={styles.ruleText}>
                            <span style={{fontSize: '2vw', marginRight: '10px'}}>👉</span> Once live, all posts are final.
                        </div>
          </div>
          <div className={styles.postBtn} onClick={() => {
            props.setIsOpen(false);
            window.location.reload();
            }} style={{marginTop: '10px'}}>Got it</div>
        </div>
        }      
        </div>
    </Modal>
  );
};
export default SendAdProposal;
