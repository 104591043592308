import { Link } from 'react-router-dom';
import logo from '../assets/images/spotlyt_wordmark_gradient.png';
import styles from '../styles/css/Navbar.module.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../service/authContext';
import {signOutUser} from '../service/firebase';
import { useEffect, useRef } from "react";

let prevScrollpos = window.pageYOffset;

const DashboardNav = (props) => {
    const navigate = useNavigate();
    const user = useAuth();

    const wrRef = useRef();
  
    // function onScroll() {
    //   var currentScrollPos = window.pageYOffset;
    //   if(currentScrollPos === 0){
    //     wrRef.current.style.top = "20px";
    //     return;
    //   }
    //   if ( prevScrollpos > currentScrollPos) {
    //     wrRef.current.style.top = "20px";
    //   } else {
    //       wrRef.current.style.top = "-50px";
    //   }
    //   prevScrollpos = currentScrollPos;
    // }
    // useEffect(() => {
    //   window.addEventListener("scroll", onScroll);
    //   return () => window.removeEventListener("scroll", onScroll);
    // }, []);

    return (
        <div ref={wrRef} className={styles.containerDash}>
        <div className={styles.leftDiv}>
            
            <div className={styles.navText}>
                <img src={logo} className={styles.lgo}/>
            </div>
        </div>
        <div className={styles.rightDiv}>
            <div className={styles.loginBtn} onClick={async () => {
                if(user){
                    await signOutUser();
                    navigate("/signin")
                }
            }}>
                {user ? "Logout" : "Creator Login"}
            </div>
        </div>
    </div>
    )
}
export default DashboardNav;