import styles from "../styles/css/UserDashboard.module.css";
import im from "../assets/images/spotlyt_logo.png";
import CustomCarousel from "../components/carousel";
import Footer from "../components/footer";
import { useEffect, useState, useRef } from "react";
import {
  getAds,
  putPendingAds,
  createConnectedAccount,
  checkForPaymentSetup,
  checkBalance,
  createExpressLoginLink,
  updatePrices,
  updateProfilePic,
  uploadImage,
  getUserProfilePic,
  getUserPrices,
  getTotalEarned
} from "../service/dataService";
import { FaRegCopy } from "react-icons/fa6";
import { useAuth } from "../service/authContext";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { AiFillLock } from "react-icons/ai";
import DashboardNav from "../components/dashboardNav";
import { changePassword } from "../service/firebase";
import st from "../assets/images/Stripe wordmark - blurple.png";
import PaymentInfoModal from "../components/paymentInfoModal";
import SendAdProposal from "../components/sendAdProposal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoPaperPlaneOutline } from "react-icons/io5";
import Share from "../components/Share";
import Tooltip from "../components/tooltip";
import { GrEdit } from "react-icons/gr";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import ProposalLearnMore from "../components/proposalLearnMore";
import PaymentReminder from "../components/paymentReminder";

const UserDashboard = () => {
  const [pendingAds, setPendingAds] = useState([]);
  const [postedAds, setPostedAds] = useState([]);
  const [onboardingDone, setOnboardingDone] = useState("");
  const [loading, setLoading] = useState(true);
  const [passLoading, setPassLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const [priceStory, setPriceStory] = useState(0);
  const [pricePost, setPricePost] = useState(0);
  const user = useAuth();
  const navigation = useNavigate();
  const [password, setPassword] = useState("");
  const [newPass, setNewPass] = useState("");
  const [passSuccess, setPassSuccess] = useState(false);
  const [priceSuccess, setPriceSuccess] = useState(false);
  const [passError, setPassError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [paymentInfoOpen, setPaymentInfoOpen] = useState(false);
  const [balanceInfo, setBalanceInfo] = useState(null);
  const [proposalOpen, setProposalOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const inputFile = useRef(null);
  const [imageToShow, setImageToShow] = useState(im);
  const [image, setImage] = useState(null);
  const [copyText, setSetCopyText] = useState("copy");
  const [canApprove, setCanApprove] = useState(false);
  const [pLearnMore, setPLearnMore] = useState(false);  
  const [totalEarned, setTotalEarned] = useState('0');
  const [paymentRemOpen, setPaymentRemOpen] = useState(true);
  const [loadingSeeDet, setLoadingSeeDet] = useState(false);

  const handleTapUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const imageUrl = URL.createObjectURL(file);
      if (imageUrl != null) {
        const iUrl = await uploadImage(file);
        await updateProfilePic(user.displayName, iUrl);
        setImageToShow(imageUrl);
        setImage(file);
      }
    }
  };

  const tapUpload = () => {
    inputFile.current.click();
  };

  const handleSetupPay = async () => {
    try {
      setPayLoading(true);
      if (onboardingDone == "Update Payment") {
        const link = await createExpressLoginLink(user.displayName);
        //window.open(link, "_blank");
        window.location.href = link;
        setPayLoading(false);
        return;
      }
      const onBoardUrl = await createConnectedAccount(
        user.email,
        user.displayName
      );
      window.location.href = onBoardUrl.redirectUrl;
      setPayLoading(false);
    } catch (err) {
      setPayLoading(false);
    }
  };
  const handleChangePassword = async () => {
    try {
      setPassLoading(true);
      setPassSuccess(false);
      setPassError(false);
      if (password.trim() == "" || newPass.trim() == "") {
        setPassError(true);
        setPassLoading(false);
        return;
      }
      await changePassword(password, newPass);
      setPassSuccess(true);
      setPassLoading(false);
    } catch (err) {
      setPassSuccess(false);
      setPassLoading(false);
      setPassError(true);
      console.log(err)
    }
  };
  const handleChangePrices = async () => {
    try {
      setPriceLoading(true);
      setPriceSuccess(false);
      setPriceError(false);
      if(pricePost < 25 || priceStory < 25 || pricePost > 2500 || priceStory > 2500){
        setPriceError(true);
        setPriceLoading(false);
        return;
      }
      await updatePrices(user.displayName, priceStory, pricePost);
      setPriceLoading(false);
      setPriceSuccess(true);
    } catch (err) {
      setPriceSuccess(false);
      setPriceError(true);
      setPriceLoading(false);
    }
  };
  const fetchPrices = async () => {
    try{
      const res = await getUserPrices(user.displayName);
      setPricePost(res.pricePost);
      setPriceStory(res.priceStory);
    } catch(err){
        
    }
  }
  const fetchProfPic = async () => {
    try {
        const profPic = await getUserProfilePic(user.displayName);
        if(profPic)
            setImageToShow(profPic);
    } catch(err){
        
    }
  }
  const fetchPendingAds = async () => {
    try {
      const docs = await getAds(user.displayName);
      let pend = [];
      let post = [];
      docs.forEach((doc) => {
        if (doc.exists()) {
          const data = doc.data();
         
          if (data.pending == true) {
            pend.push({
              image_url: data.image_url,
              owner: data.owner,
              type: data.post_type,
              adId: doc.id,
              pending: true,
              is_paid: data.is_paid,
              message: data.message,
              video_url: data.video_url
            });
          } else {
            post.push({
              image_url: data.image_url,
              owner: data.owner,
              type: data.post_type,
              adId: doc.id,
              pending: false,
              is_paid: data.is_paid,
              message: data.message,
              video_url: data.video_url
            });
          }
        }
      });
      setPendingAds(pend);
      setPostedAds(post);
    } catch (err) {
      
    }
  };
  const checkForPayment = async () => {
    try {
      const res = await checkForPaymentSetup(user.displayName);
      if (res.status == "not finished"){
        setCanApprove(false);
        setOnboardingDone("Continue onboarding");
      }
      else if (res.status == "not started"){
        setCanApprove(false);
        setOnboardingDone("Set up Payments");
      }
      else {
        setCanApprove(true);
        setOnboardingDone("Update Payment");
        await fetchBalance();
      }
      console.log(res.status)
    } catch (err) {
      
    }
  };
  const fetchBalance = async () => {
    try {
      const res = await checkBalance(user.displayName);
      setBalanceInfo(res.balance);
     console.log(res)
    } catch (err) {
      
    }
  };
  const fetchTotalEarned = async () => {
    try {
      const tEarned =  await getTotalEarned(user.displayName);
      setTotalEarned(tEarned);
    } catch(err) {

    }
  }
  const fetchData = async () => {
    try {
      setLoading(true);
      await fetchPendingAds();
      fetchPrices();
      checkForPayment();
      fetchProfPic();
      fetchTotalEarned();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (user) fetchData();
  }, [user]);

  if (loading || !user) {
    return (
      <div className={styles.loading}>
        <Oval
          height={80}
          width={80}
          color="#ffffff"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#ffffff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <DashboardNav onPress={() => navigation("/signin")} />
      <SendAdProposal
              modalIsOpen={proposalOpen}
              setIsOpen={setProposalOpen}
              canApprove={canApprove}
            />
      <ProposalLearnMore modalIsOpen={pLearnMore} setIsOpen={setPLearnMore} />
      {onboardingDone != "" && onboardingDone != "Update Payment" && <PaymentReminder modalIsOpen={paymentRemOpen} setIsOpen={setPaymentRemOpen} handleSetupPay={handleSetupPay}/>}
      <div className={styles.header}>
        <div className={styles.headerCont}>
          <div className={styles.profileCard}>
            <div className={styles.picWrap}>
              <div  className={styles.profilePicture} onClick={() => tapUpload()}>
              <img src={imageToShow} alt="Profile" />
              <div className={styles.edit}>
                <GrEdit size={22} color={"#999999"}/>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={handleTapUpload}
                  accept="image/*"
                />
              </div>
              </div>
            </div>
            <div className={styles.mobHead}>

            <div className={styles.username}>@{user.displayName}</div>
            <div className={styles.share}>
              <div> spotlyt.app/{user.displayName} </div>
              &#160; &#160;
              <div onMouseLeave={() => setSetCopyText("Copy")}>
                <Tooltip text={copyText}>
                  <CopyToClipboard
                    text={`spotlyt.app/${user.displayName}`}
                    onCopy={() => {
                      setSetCopyText("Copied");
                    }}
                  >
                    <FaRegCopy style={{ cursor: "pointer" }} />
                  </CopyToClipboard>
                </Tooltip>
              </div>
              &#160; &#160;
              <Share  modalIsOpen={shareOpen}
              setIsOpen={() => {
                setShareOpen(false);
                }} url={`spotlyt.app/${user.displayName}`}/>
              <div style={{cursor: 'pointer'}} onClick={() => setShareOpen(true)}>
              <Tooltip text="Share">
                <IoPaperPlaneOutline/>
              </Tooltip>
              </div>
              
            </div>
            </div>
            <div style={{position: 'relative'}}>              
              <AiOutlineQuestionCircle className={styles.prpLearn} color="#000000" size={22} onClick={() => setPLearnMore(true)}/>
            <div
              className={styles.adProp}
              style={{ marginTop: "20px" }}
              onClick={() => setProposalOpen(true)}
            >
              Propose an Ad or Collaboration
            </div>
            </div>
          </div>
          
        </div>
        <div style={{position: 'relative'}}>
        <AiOutlineQuestionCircle className={styles.prpLearnMob} color="#000000" size={22} onClick={() => setPLearnMore(true)}/>
        <div
              className={styles.adPropMob}
              style={{ marginTop: "20px" }}
              onClick={() => setProposalOpen(true)}
            >
              Propose an Ad or Collaboration
            </div>
            </div>
        <div className={styles.metCont}>
          {/* <div className={styles.metric}>
            <span className={styles.mIcons}>📈</span> &#160;Posts sold 15
          </div> */}
          <div className={styles.metric}>
            <span className={styles.mIcons}>💰 </span>&#160;Total earned to
            date ${totalEarned}
          </div>
          <div
            className={onboardingDone == "Update Payment" ? styles.secButn : styles.payDisabled}
            style={{ marginTop: "20px" }}
            onClick={async () => {
              setLoadingSeeDet(true);
              const link = await createExpressLoginLink(user.displayName);
              window.location.href = link;
            }}
          >
            {loadingSeeDet ? <Oval
                      height={22}
                      width={22}
                      color="#ffffff"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />: "More Payment Info"}
          </div>
         {balanceInfo && <PaymentInfoModal
            modalIsOpen={paymentInfoOpen}
            setIsOpen={setPaymentInfoOpen}
            info={balanceInfo}
            totalEarned={totalEarned}
            openExpress={async () => {
              const link = await createExpressLoginLink(user.displayName);
            //  window.open(link, "_blank");
              window.location.href = link;
            }}
            onboardingDone={onboardingDone}
          />}
        </div>
      </div>
      <div className={styles.adCon}>
        <div className={styles.pending}>
          <div
            className={styles.titleText}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className={styles.appText}>Pending Posts</div>

            <div style={{ fontSize: "2.4vw" }}>
              <span className={styles.extraText}>{pendingAds.length} 💌</span>
            </div>
          </div>
          <CustomCarousel ads={pendingAds} switchAd={fetchData} canApprove={canApprove}/>
        </div>
        <div className={styles.posted}>
          <div
            className={styles.titleText}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className={styles.appText}>Approved Posts</div>

            <div style={{ fontSize: "2.4vw" }}>
              <span className={styles.extraText}>{postedAds.length} 📌</span> 
            </div>
          </div>
          <CustomCarousel ads={postedAds} switchAd={() => {}} canApprove={canApprove}/>
        </div>
      </div>
      <div className={styles.settings}>
        <div className={styles.pending}>
          <div className={styles.titleText}>Settings & Account</div>
          <div className={styles.upper}>
            <div className={styles.card}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className={styles.stripeText}
                >
                  Update your payment account with
                  <span style={{ marginLeft: "8px", marginTop: "3px" }}>
                    <img src={st} className={styles.stLogo} />
                  </span>
                </div>
                <div
                  className={styles.secButnSet}
                  onClick={() => handleSetupPay()}
                >
                  {payLoading ? (
                    <Oval
                      height={22}
                      width={22}
                      color="#ffffff"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    onboardingDone ? onboardingDone : 'Update Payout Method'
                  )}
                </div>
              </div>
            </div>
            <div className={styles.cardPass}>
              <div style={{ width: "100%", height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div className={styles.chgPass}>Change Password</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <AiFillLock size={23} color="#999999" />
                  <input
                    className={styles.inp}
                    placeholder="Current Password"
                    style={{ width: "100%", marginLeft: "5px" }}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <AiFillLock size={23} color="#999999" />
                  <input
                    className={styles.inp}
                    placeholder="New Password"
                    style={{ width: "100%", marginLeft: "5px" }}
                    type="password"
                    onChange={(e) => setNewPass(e.target.value)}
                  />
                </div>
                <div
                  className={styles.secButnSet}
                  style={{ marginTop: "20px" }}
                  onClick={() => handleChangePassword()}
                >
                  {passLoading ? (
                    <Oval
                      height={22}
                      width={22}
                      color="#ffffff"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    "Change Password"
                  )}
                </div>
                {passSuccess && (
                  <div style={{ color: "green", marginTop: "10px" }}>
                    Password Updated!
                  </div>
                )}
                {passError && (
                  <div style={{ color: "#e06666", marginTop: "10px" }}>
                    Please check your password.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.upper} style={{ marginTop: "20px" }}>
            <div className={styles.card}>
              <div style={{ width: "100%" }}>
                <div style={{ marginTop: "5px" }}>
                  <div className={styles.priceText}>
                    Price per Story (between $25 and $2500)
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    $
                    <input
                      className={styles.inpPr}
                      type="number"
                      onChange={(e) => setPriceStory(e.target.value)}
                      value={priceStory}
                      min="25"
                      max="2500"
                    />
                  </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <div className={styles.priceText}>
                    Price per Post (between $25 and $2500)
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    $
                    <input
                      className={styles.inpPr}
                      type="number"
                      onChange={(e) => setPricePost(e.target.value)}
                      value={pricePost}
                      min="25"
                      max="2500"
                    />
                  </div>
                  <div
                    className={styles.secButnSet}
                    style={{ marginTop: "40px" }}
                    onClick={() => handleChangePrices()}
                  >
                    {priceLoading ? (
                      <Oval
                        height={22}
                        width={22}
                        color="#ffffff"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffff"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : (
                      "Set & Update"
                    )}
                  </div>
                  {priceSuccess && (
                    <div style={{ color: "green", marginTop: "10px" }}>
                      Prices Updated!
                    </div>
                  )}
                  {priceError && (
                    <div style={{ color: "#e06666", marginTop: "10px" }}>
                      Please set a price between $25 and $2500.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default UserDashboard;
