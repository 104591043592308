import { Link } from 'react-router-dom';
import logo from '../assets/images/spotlyt_wordmark_gradient.png';
import styles from '../styles/css/Navbar.module.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../service/authContext';
import {signOutUser} from '../service/firebase';
import { useEffect, useRef, useState } from "react";
import HamburgerMenu from './hamburgerMenu';

let prevScrollpos = window.pageYOffset;

const Navbar = (props) => {
    const navigate = useNavigate();
    const user = useAuth();

    const wrRef = useRef();
    const [clicked, setClicked] = useState(false);
    return (
        <div ref={wrRef} className={styles.container} style={{}}>
        <div className={styles.leftDiv}>
        <HamburgerMenu clicked={clicked} setClicked={setClicked} handleClick={props.handleClick} handleClickCalc={() => {
            if(props.handleCLickCalc)
                props.handleCLickCalc();
            else 
                navigate("/")
            }}/>

            <div className={styles.navText} onClick={() => navigate("/")}>
                <img src={logo} className={styles.lgo}/>
            </div>
            <div onClick={() => {
                if(props.handleClick)
                    props.handleClick();
                else
                    navigate("/")
            }}
            className={styles.abtTe}>About
            </div>
            <div onClick={() => {
                window.location.href = 'http://blogspotlyt.wordpress.com/';
            }}
            className={styles.abtTe}>Blog
            </div>
            <div onClick={() => {
                if(props.handleCLickCalc)
                    props.handleCLickCalc();
                else
                    navigate("/")
            }}
            className={styles.abtTe}>Price Calculator
            </div>
        </div>
        <div className={styles.rightDivDash}>
            <div className={styles.loginBtn} onClick={async () => {
                console.log("dsd")
                if(user){
                    navigate("/dashboard")
                }
                else{
                    navigate("/signin")
                }
            }}>
                {user ? "Dashboard" : "Creator Login"}
            </div>
        </div>
    </div>
    )
}
export default Navbar;