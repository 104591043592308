import React, { useState } from 'react';
import styles from '../styles/css/Navbar.module.css';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/spotlyt_wordmark_gradient.png';
import { useAuth } from '../service/authContext';

const HamburgerMenu = (props) => {
  const navigate = useNavigate();
  const user = useAuth();

  return (
    <div className={styles.hamburgerMenu}>
        <div
        onClick={() => {
            document.body.style.overflow = props.clicked ? 'auto' : 'hidden';
            props.setClicked((prev) => !prev)
        }}
        className={styles.menuIt}
      >
        <div
          className={props.clicked ? `${styles.menu1} ${styles.anim1}` : styles.menu1}
        />
        <div
          className={props.clicked ? `${styles.menu2} ${styles.anim2}` : styles.menu2}
        />
      </div>
      <div
        className={
          props.clicked ? `${styles.hamWrapper} ${styles.hamIn}` : styles.hamWrapper
        }
        style={{display: props.clicked ? 'flex': 'none'}}
      >
        <div style={{ width: '100%'}}>
          <div className={styles.hamIt}>
            <div
              style={{ marginTop: "50px" }}
              onClick={() => {
                document.body.style.overflow = 'auto';
                props.setClicked(false)
                if(props.handleClick)
                    props.handleClick();
                else
                    navigate("/")
            }}
            >
              About
            </div>
          </div>
          <div onClick={() => {
                document.body.style.overflow = 'auto';
                window.location.href = 'http://blogspotlyt.wordpress.com/';
            }} className={styles.hamIt}>
            <div style={{ marginTop: "50px" }}>Blog</div>
          </div>
          <div className={styles.hamIt}>
            <div
              style={{ marginTop: "50px" }}
              onClick={() => {
                document.body.style.overflow = 'auto';
                props.setClicked(false)
                
                if(props.handleClickCalc)
                    props.handleClickCalc();
                else
                    navigate("/")
            }}
            >
              Price Calculator
            </div>
          </div>
          <div onClick={async () => {
            document.body.style.overflow = 'auto';
                if(user){
                    navigate("/dashboard")
                }
                else{
                    navigate("/signin")
                }}} className={styles.hamIt}>
            <div style={{ marginTop: "50px" }}>{user ? "Dashboard" : "Creator Login"}</div>
          </div>
        </div>
        <div className={styles.navText} onClick={() => {
            document.body.style.overflow = 'auto';
            navigate("/")
        }}>
                <img src={logo} className={styles.lgoHam}/>
            </div>
        </div>
  </div>
  );
};

export default HamburgerMenu;
