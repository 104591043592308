import styles from "../styles/css/Signup.module.css";
import eLogo from "../assets/images/email_icon.png";
import { BsFillPersonFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiFillInstagram } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef} from "react";
import { createWithEmailAndPassword, verifyEmail } from "../service/firebase";
import { putUser, getInstagramUserData } from "../service/dataService";
import { useAuth } from "../service/authContext";
import vid from "../assets/images/spotlyt_landing_background.mp4";
import Navbar from "../components/navbar";
import logo from "../assets/images/spotlyt_logo.png";
import water from "../assets/images/spotlyt_wordmark_gradient.png";
import { TfiInstagram } from "react-icons/tfi";
import { AiFillLock } from "react-icons/ai";
import { InstagramLogin } from "@amraneze/react-instagram-login";
import axios from "axios";
import insta from "../assets/images/instagram_wordmark_white.png";
import { Oval } from "react-loader-spinner";
import {userNameExists, sendVerificationEmail} from '../service/dataService';

const Signup = () => {
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [instaLink, setInstaLink] = useState("");
  const [instaUserName, setInstaUsername] = useState(null);
  const [instaDisplay, setInstaDisplay] = useState("");
  const [genError, setGenError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const divRef = useRef(null);
  const [userExistsError, setUserExistsError] = useState(false);
  const [instaLoading, setInstaLoading] = useState(false);
  const [defaultError, setDefaultError] = useState(false);
  
  const user = useAuth();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleNext = async () => {
    try {
      setLoading(true);
      setDefaultError(false);
    //   setPasswordError(false);
    //   setEmailError(false);
    //   setGenError(false);
    //   if (
    //     firstName.trim() == "" ||
    //     lastName.trim() == "" ||
    //     email.trim() == ""
    //   ) {
    //     setGenError(true);
    //     setLoading(false);
    //     return;
    //   }
    //   if (!validateEmail(email)) {
    //     setEmailError(true);
    //     setLoading(false);
    //     return;
    //   }
    //   if (password.length < 6) {
    //     setPasswordError(true);
    //     setLoading(false);
    //     return;
    //   }
    if(await userNameExists(instaUserName)){
      setUserExistsError(true);
      setInstaLoading(false);
      return;
    }
      const user = await createWithEmailAndPassword(
        email,
        password,
        instaUserName
      );
      const uid = user.uid;
      await sendVerificationEmail(email);
      await putUser(firstName, lastName, email, instaUserName, uid);
      navigation("/prices");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setDefaultError(true);
      setInstaLoading(false);
      console.log(err);
    }
  };
  const validateInputs = () => {
    try {
        setPasswordError(false);
        setEmailError(false);
        setGenError(false);
        if (
          firstName.trim() == "" ||
          lastName.trim() == "" ||
          email.trim() == ""
        ) {
          setGenError(true);
          setLoading(false);
          return false;
        }
        if (!validateEmail(email)) {
          setEmailError(true);
          setLoading(false);
          return false;
        }
        if (password.length < 6) {
          setPasswordError(true);
          setLoading(false);
          return false;
        }
        return true;
    } catch(err){
        console.log(err);
    }
  }
  const responseInstagramError = (res) => {
    console.log(res);
  };

  const responseInstagram = async (response) => {
    try {
      setInstaLoading(true);
      const userObj = await getInstagramUserData(response);
      console.log(userObj)
      const res = await axios.get(
        `https://graph.instagram.com/me?fields=username,account_type&access_token=${userObj.access_token}`
      );
      setInstaUsername(res.data.username);
    } catch (err) {
      setInstaLoading(false);
      console.log(err);
    }
  };
    useEffect(() => {
      if(firstName.trim() != "" && lastName.trim() != "" && email.trim() != "" && instaUserName != null){
          handleNext();
      }
    }, [instaUserName])

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.topC}>
        <div className={styles.abs}>
          <img src={logo} className={styles.lgo} />

          <div className={styles.formC}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div className={styles.welc}>Welcome to</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <img src={water} style={{ height: "40px" }} />
            </div>
            <div className={styles.innerF}>
              <div className={styles.nameC}>
                <BsFillPersonFill size={22} color="#999999" />
                <input
                  className={styles.inpF}
                  placeholder="First Name"
                  style={{ marginLeft: "5px" }}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  className={styles.inpL}
                  placeholder="Last Name"
                  style={{}}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MdEmail size={22} color="#999999" />
                <input
                  className={styles.inp}
                  placeholder="Email"
                  style={{ width: "100%", marginLeft: "5px" }}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AiFillLock size={23} color="#999999" />
                <input
                  className={styles.inp}
                  placeholder="Password"
                  style={{ width: "100%", marginLeft: "5px" }}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div style={{}} className={styles.pRule}>
                Passwords must be at least 6 characters
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "25px",
                }}
              >
                <TfiInstagram size={20} color="#999999" />
                <input
                  className={styles.inp}
                  placeholder="Instagram Username"
                  style={{ width: "100%", marginLeft: "5px" }}
                  type="text"
                  onChange={(e) => setInstaDisplay(e.target.value)}
                  value={instaUserName ? instaUserName : instaDisplay}
                />
              </div>

              <InstagramLogin
                clientId="231662468880803"
                onSuccess={responseInstagram}
                onFailure={responseInstagramError}
                redirectUri="https://www.spotlyt.app/signup"
                scope="user_profile"
                cssClass={styles.instaButtonReal}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  ref={divRef}

                >
                  <div style={{ marginRight: "6px", marginBottom: '2px' }} className={styles.verifyText}>Verify with</div>
                  <img src={insta} className={styles.instaLogo} />
                </div>
              </InstagramLogin>
              <div
                className={styles.instaButton}
                onClick={async () => {
                    if(validateInputs())
                        divRef.current.click();
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {instaLoading ? <Oval
                      height={30}
                      width={30}
                      color="white"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel='oval-loading'
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                      secondaryColor="#ffffff"
                   /> : <div style={{display: 'flex'}}>
                    <div style={{ marginRight: "6px", marginBottom: '2px' }} className={styles.verifyText}>Verify with</div>
                    <img src={insta} className={styles.instaLogo} />
                  </div>}
                </div>
              </div>
            </div>

            {passwordError && (
              <div style={{ color: "#e06666" }}>
                Passwords must be at least 6 characters.
              </div>
            )}
            {emailError && (
              <div style={{ color: "#e06666" }}>
                Please enter a valid email.
              </div>
            )}
            {/* <div style={{color: 'red'}}>Please provide your first and last name.</div> */}
            {genError && (
              <div style={{ color: "#e06666" }}>
                Please fill out all required fields.
              </div>
            )}
            {userExistsError && (
              <div style={{ color: "#e06666" }}>
                Looks like this username is already taken!
              </div>
            )}
            {defaultError && (
              <div style={{ color: "#e06666" }}>
                An error occured. Please refresh the page and try again.
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <span style={{ color: "white" }} className={styles.haveText}>Have an account?</span>{" "}
            <span
              style={{
                marginLeft: "5px",
                cursor: "pointer",
                color: "white",
                fontFamily: "proxima-bold",
              }}
              className={styles.haveText}
              onClick={() => {
                navigation("/signin");
              }}
            >
              Sign in
            </span>
          </div>
        </div>
      </div>
      <div className={styles.layer}></div>
    </div>
  );
};
export default Signup;
/**
 * bring button down
 */
