import {
  FacebookShareButton,
  EmailShareButton,
  FacebookIcon,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { AiFillCloseCircle } from "react-icons/ai";
import Modal from "react-modal";
import styles from '../styles/css/Share.module.css';
import { useEffect } from "react";
import { IoPaperPlaneOutline } from "react-icons/io5";

const Share = (props) => {
  return (
    <Modal
      isOpen={props.modalIsOpen}
      style={{
        overlay: {
          zIndex: 200,
          backgroundColor: "rgb(0,0,0, 0.7)",
        },
        content: {
          zIndex: 200,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'none',
          boxSizing: 'border-box',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent'
      }
      }}
    >
      <div className={styles.modalCont}>
      <div className={styles.close} onClick={() => {
        
        props.setIsOpen(false);
        }}>
                <AiFillCloseCircle size={25}/>
            </div>
        <div
          style={{
            background: "#0000",
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
          className={styles.shareText}>
            Share your link 
            <IoPaperPlaneOutline size={30} style={{marginLeft: '10px'}} /></div>
        <div className={styles.btnCont}>
          <FacebookShareButton
            url={props.url}
            quote={"Share your spotlyt Link"}
            hashtag={"#Spotlyt..."}
          >
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>

          <EmailShareButton
            url={props.url}
            quote={"Share your spotlyt Link"}
            hashtag={"#Spotlyt..."}
          >
            <EmailIcon size={40} round={true} />
          </EmailShareButton>
          <TwitterShareButton
            url={props.url}
            quote={"Share your spotlyt Link"}
            hashtag={"#Spotlyt..."}
          >
            <TwitterIcon size={40} round={true} />
          </TwitterShareButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default Share;
