import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import styles from '../styles/css/LearnModal.module.css';

const LearnMoreModal = (props) => {
    return (
        <Modal
        isOpen={props.modalIsOpen}
        style={{
            overlay: {
                zIndex: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgb(0,0,0, 0.7)'
            },
            content: {
                zIndex: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                boxSizing: 'border-box',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'transparent'
            }
        }}
    >
        <div className={styles.modalCont}>
            <div className={styles.titles} style={{color: 'white'}}>What is Spotlyt?</div>
            <div className={styles.pText}>
            Your influence is valuable. &#160;Spotlyt lets creators like 
                you charge businesses to post ads and collaborations to your 
                Instagram channel. &#160;They'll send you the content. &#160;You review it and post it when paid.
                &#160;Share your Spotlyt link in your bio or Linktree to 
                interact with clients.
            </div>
            <div className={styles.titles} style={{marginTop: '20px', color: 'white'}}>How does it work?</div>
            <div className={styles.pText}>
                Set your 
                prices between $25 and $2500. &#160;Once paid, you 
                have 48 hours to post the image to Instagram.
                &#160;Wall posts must stay up a minimum of seven (7) days. &#160;Stories 
                must stay up 24 hours. &#160;We retain a 20% commission on 
                each post sold.
                <br></br>  
                <br></br> 
                You can propose posts yourself by creating them from your 
                Spotlyt dashboard. &#160;Reach out to businesses or other channels. &#160;Expand their reach and help them grow.
            </div>
            <div className={styles.signUpBtn} onClick={() => props.setIsOpen(false)}>
                Got it
            </div>
        </div>
    </Modal>
    )
}
export default LearnMoreModal;