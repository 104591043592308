import Navbar from "../components/navbar";
import styles from "../styles/css/Landing.module.css";
import landing from "../assets/images/spotlyt_ad6.png";
import sv from "../assets/images/pppointed2.svg";
import sv2 from "../assets/images/new_arrow.svg";
import wall from "../assets/images/spotlyt_graphics3b.png";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import abtLogo from "../assets/images/spotlyt_wordmark_trim2.png";
import spotBack from "../assets/images/coke_bg2.jpg";
import insta from "../assets/images/instagram_wordmark_white.png";
import { useIsVisible } from "../components/useVisible";
import LearnMoreModal from "../components/learMoreModal";
import shGr from '../assets/images/link_point_4.png'
import JointWaitlistModal from "../components/joinWaitlistModal";
import { TfiInstagram } from "react-icons/tfi";
import logo from "../assets/images/spotlyt_logo.png";
import { useParams } from 'react-router-dom';

const Landing = () => {
  const navigate = useNavigate();
  const myRef = useRef(null);
  const myRefCalc = useRef(null);
  const isVisible = useIsVisible(myRef);
  const [learnOpen, setLearnOpen] = useState(false);
  const [waitlistOpen, setWaitlistOpen] = useState(false);
  const [numOfFoll, setNumOfFoll] = useState(0);
  const [calcDone, setCalcDone] = useState(false);
  const [xRange, setXRange] = useState(0);
  const [yRange, setYRange] = useState(0);
  const {calc} = useParams();
  
  useEffect(() => {
    if (calc) {
      const sectionElement = document.getElementById(calc);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [calc]);

  const calculatePriceRange = () => {
    if(numOfFoll == 0){
      return
    }
    let x = ((0.2 * numOfFoll)/1000)*17.50;
    let y = ((0.3 * numOfFoll)/1000)*25;
    setXRange(parseInt(x));
    setYRange(parseInt(y));
    setCalcDone(true);
  }
  return (
    <>
      <LearnMoreModal modalIsOpen={learnOpen} setIsOpen={setLearnOpen} />
      <JointWaitlistModal modalIsOpen={waitlistOpen} setIsOpen={setWaitlistOpen}/>
      <Navbar handleClick={() => myRef.current.scrollIntoView()} handleCLickCalc={() => myRefCalc.current.scrollIntoView()}/>
      <div className={styles.container}>
        <div className={styles.overlay}>
        </div>
        <div className={styles.midC} style={{}}>
          <div className={styles.leftC}>
            <div className={styles.mainText}>Make Ads and Collaborations</div>
            <div className={styles.mainText}>on the Spot.</div>
            <div className={styles.mobMain} style={{marginBottom: '5vh'}}>
              <div className={styles.secText} style={{ marginTop: "20px"}}>Put your Spotlyt link in your bio or Linktree</div>
              <div className={styles.secText} >and earn money for your </div>
              <div className={styles.secText}><img src={insta} className={styles.instaLogo} style={{marginRight: '8px'}}/>posts.</div>
            </div>


              <div className={styles.mobDesk}>
              <div className={styles.secText} style={{ marginTop: "20px"}}>
                Put your Spotlyt link in your bio or Linktree
              </div>
              <div className={styles.secText} style={{marginTop: '20px', marginBottom: '20px'}}>and earn money for your <img src={insta} className={styles.instaLogo} style={{marginLeft: '10px', marginRight: '10px'}}/>posts.</div>
              </div>
            <br/>
            <div className={styles.signupCont}>
              <div
                className={styles.signUpBtn}
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Sign Up
              </div>
              <div
                style={{ cursor: "pointer", fontFamily: "proxima-semi-bold" }}
                className={styles.learnM}
                onClick={() => {
                  setLearnOpen(true);
                }}
              >
                Learn more
              </div>
            </div>
          </div>
          <div className={styles.rightC}>
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: 'flex-start',
                width: '100%'
              }}
            >
              <div className={styles.numText}>1</div>
              <div className={styles.stepText}>Take a photo or video of your experience.</div>
            </div>
            <div className={styles.insta}>
              <img src={landing} className={styles.landPic} />
              {/* <div className={styles.abs}>
                <img src={sv2} className={styles.arr} />
              </div> */}
            </div>
          </div>
        </div>
        <div className={styles.thirdStepC} style={{ }}>
          <div className={styles.purple}>
            <div style={{width: '100%'}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: 'flex-start',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  position: 'relative'
                }}
              >
                {/* <div className={styles.abs2}>
                  <img src={sv2} className={styles.arr2} />
                </div> */}
                <div className={styles.numText3}>2</div>
                <div className={styles.stepText3}>
                  Post it to your IG story or wall.
                </div>
              </div>
            </div>
            <img src={wall} className={styles.inWall} />
          </div>

          <div className={styles.purple2}>
            <div
              style={{ display: "flex", display: "flex", alignItems: "center" }}
            >
              <div className={styles.numText3}>3</div>
              <div className={styles.stepText3}>Earn money for your posts.</div>
            </div>
            <div
              className={styles.step3Text}
              style={{
                marginTop: "20px",
                marginLeft: "44px",
                whiteSpace: "normal",
                fontFamily: "proxima-bold",
              }}
            >
              <span style={{}}>🏷️</span> Set your own
              prices
            </div>
            <div
              className={styles.step3Text}
              style={{
                marginLeft: "44px",
                marginTop: "10px",
                fontFamily: "proxima-bold",
              }}
            >
              <span style={{}}>📲</span> Share your link
            </div>
            <div
              className={styles.step3Text}
              style={{
                marginLeft: "44px",
                marginTop: "10px",
                fontFamily: "proxima-bold",
              }}
            >
              <img src={shGr} className={styles.shrGr}/>
            </div>
            <div
              className={styles.step3Text}
              style={{
                marginLeft: "44px",
                marginTop: "10px",
                fontFamily: "proxima-bold",
              }}
            >
              <span style={{}}>💵 </span> Get paid on the spot
            </div>
          </div>
        </div>

        <section className={styles.calculatorSec} ref={myRefCalc} id="calc">
          <div className={styles.calcCont}>
          <img src={abtLogo} className={styles.abtLgo2} />
          <div className={styles.abtPara2}>
              Ad Price Calculator.
            </div>
            <div className={styles.abtPara3}>
              How much should I charge per post?
            </div>
            {!calcDone && <div>
              <div className={styles.doPrice}>
                <TfiInstagram size={40} color="#000000" style={{marginRight: '10px', fontWeight: 'bold'}}/>
                  <div className={styles.foll}>
                    Followers
                  </div>
                  <div className={styles.follInpCont}>
                    <input type="number" className={styles.follInp} onChange={(e) => setNumOfFoll(e.target.value)} placeholder="50000" step="1000"/>
                  </div>
              </div>
            <div className={styles.btnFol}>
              <div
                  className={styles.getStart2}
                  onClick={() => {
                    calculatePriceRange();
                  }}
                >
                  Calculate Price Range
                </div>
              </div>
            </div>}
          {calcDone && <div className={styles.calcDone}>
            <div style={{display: 'flex'}}>
              <img src={logo} className={styles.lgo} />
              <div className={styles.calcRes}>
                Spotlyt estimates you should charge between ${xRange} and ${yRange} per post to your Instagram Story or Wall. <span className={styles.learnCalc}
                onClick={() => window.location.href = 'https://blog.spotlyt.app/2024/03/23/how-much-should-i-charge-for-an-instagram-ad-or-collaborative-post/'} >{"(Learn more)"}</span>
              </div>
          </div>
          <div className={styles.btnFol}>
              <div
                  style={{marginBottom: 0}}
                  className={styles.getStart2}
                  onClick={() => {
                    navigate("/signup")
                  }}
                >
                  Start Earning
                </div>
              </div>
              <div className={styles.recCal} onClick={() => {
                setCalcDone(false);
                setNumOfFoll(0);
                setXRange(0);
                setYRange(0);
              }}>
                Recalculate
              </div>
          </div>}
          </div>

        </section>

        <div className={styles.abtSec} ref={myRef}>
          <div
            className={
              isVisible ? `${styles.abtIm} ${styles.zoomBack}` : styles.abtIm
            }
          ></div>
          <div className={styles.abtCont} >
            <img src={abtLogo} className={styles.abtLgo} />
            <div className={styles.abtHead}>Movers, Shakers, Content Creators.</div>
            <div className={styles.abtPara}>
            Collaborate and get paid 10X faster than using emails, DMs, and separate payment platforms.
            </div>
            <div className={styles.abtBtnCont} style={{marginTop: '30px'}}>
              <div
                className={styles.getStart}
                onClick={() => {
                  //setWaitlistOpen(true);
                  navigate("/signup")
                }}
              >
                Get Spotlyt
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Landing;
