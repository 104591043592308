import './App.css';
import Landing from './pages/landing';
import { Routes, Route } from "react-router-dom";
import UserProfile from './pages/userProfile';
import Signup from './pages/signUp';
import SetPrices from './pages/setPrices';
import Signin from './pages/signIn';
import UserDashboard from './pages/userDashboard';
import { useAuth } from  '../src/service/authContext';
import PageNotFound from './pages/pageNotFound';
import CheckoutPage from './pages/checkoutPage';
import PaymentConfirmation from './pages/paymentConfirmation';
import ForgotPassword from './pages/forgotPassword';
import VerifyInstagram from './pages/verifyInstagram';
import VerifyEmail from './pages/verifyEmail';
import ResetPassword from './pages/resetPassword';
import ManageUser from './pages/manageUser';

function App() {
  const user = useAuth();

  return (
    <Routes>
          <Route path="/" element={<Landing/>} />
          <Route path="/ad/:calc" element={<Landing/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/:userName" element={<UserProfile/>} />
          <Route path="/dashboard" element={<UserDashboard/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/verify" element={<VerifyInstagram/>} />
          <Route path="/prices" element={<SetPrices/>} />
          <Route path="/signin" element={<Signin/>} />
          <Route path="/forgotPassword" element={<ForgotPassword/>} />
          <Route path="/checkout/:userName/:adId/:type/:email" element={<CheckoutPage/>} />
          <Route path="/complete/:adId/:userName/:customerEmail/:adPrice" element={<PaymentConfirmation/>} />
          <Route path="/manage" element={<ManageUser/>} />
    </Routes>
  );
}

export default App;