import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import styles from '../styles/css/PaymentInfoModal.module.css';
import { useState } from 'react';
import { Oval } from "react-loader-spinner";

const PaymentInfoModal = (props) => {
    const [loading, setLoading] = useState(false);

    return (
        <Modal
        isOpen={props.modalIsOpen}
        style={{
            overlay: {
                zIndex: 200,
                backgroundColor: 'rgb(0,0,0, 0.7)'
            },
            content: {
                zIndex: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                boxSizing: 'border-box',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'transparent'
            }
        }}
    >
        <div className={styles.modalCont}>
            <div className={styles.close} onClick={() => props.setIsOpen(false)}>
                <AiFillCloseCircle size={25}/>
            </div>
            <div className={styles.titles} style={{}}>Payment Information</div>
               {props.onboardingDone && (props.onboardingDone === "Set up Payment" || props.onboardingDone === "continue onboarding") ?
               <div>Please finish setting up your payments with Stripe to access your payment history.</div>:
               <div>
                <div>Total earned: {props.totalEarned}</div>
                <div>Instant payment available: {props.info ? props.info.instant_available[0].amount: ''}</div>
                <div>Pending {props.info ? props.info.pending[0].amount: ''}</div>
                <div>Payouts are made everyday</div>
            <div className={styles.signUpBtn} onClick={async () => {
                setLoading(true);
                    await props.openExpress()
                setLoading(false);
                }}>{
                    loading ?  <Oval
                    height={20}
                    width={20}
                    color="#ffffff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#ffffff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />: "See Details"
                }
                
            </div>
            </div>
            }
        </div>
    </Modal>
    )
}
export default PaymentInfoModal;