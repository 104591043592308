import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import styles from '../styles/css/LearnModal.module.css';

const LearnMoreUserProfile = (props) => {
    return (
        <Modal
        isOpen={props.modalIsOpen}
        style={{
            overlay: {
                zIndex: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgb(0,0,0, 0.7)'
            },
            content: {
                zIndex: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                boxSizing: 'border-box',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'transparent'
            }
        }}
    >
        <div className={styles.modalCont}>
            {/* <div className={styles.close} onClick={() => props.setIsOpen(false)}>
                <AiFillCloseCircle size={25}/>
            </div> */}
            <div className={styles.titles} style={{color: 'white'}}>What is Spotlyt?</div>
            <div className={styles.pText}>
            Spotlyt lets you post ads and collaborations to a creator's 
                Instagram channel to promote your brand, business, or channel. &#160;
                Posting content with Spotlyt can expand your reach, gain you 
                exposure, and help you grow.
            </div>
            <div className={styles.titles} style={{marginTop: '20px', color: 'white'}}>How does it work?</div>
            <div className={styles.pText}>
            Upload an image to Spotlyt and the creator will review it. &#160;When 
            you purchase a post, the creator has 48 hours to post it to their 
            channel. &#160;Wall posts stay up a minimum of seven (7) days. &#160;Stories
            stay up for 24 hours. &#160;Grow your brand, business, or channel with 
            Spotlyt.
            </div>
            <div className={styles.signUpBtn} onClick={() => props.setIsOpen(false)}>
                Got it
            </div>
        </div>
    </Modal>
    )
}
export default LearnMoreUserProfile;