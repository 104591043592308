import { addDoc, getFirestore } from "firebase/firestore";
import {app} from './firebase';
import { doc, setDoc, updateDoc, getDoc, getDocs, collection, deleteDoc } from "firebase/firestore"; 
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";

const db = getFirestore(app);
const storage = getStorage();

const putUser = async (firstN, lastN, email, instaUsername, uid) => {
    try{
        await setDoc(doc(db, 'users', instaUsername), {
            first_name: firstN,
            last_name: lastN,
            instgram_link: `https://www.instagram.com/${instaUsername}`,
            email: email,
            uid: uid,
            instagram_username: instaUsername,
            price_per_post: 40,
            price_per_story: 40
        })

    } catch(err){
        throw err;
    }
}
const updatePrices =  async (instaUsername, price_story, price_post) => {
    try{
        await updateDoc(doc(db, 'users', instaUsername), {
            price_per_story: parseInt(price_story),
            price_per_post: parseInt(price_post)
        })

    } catch(err){
        throw err;
    }
}
const addToApproved =  async (instaUsername, adID) => {
  try{
      await updateDoc(doc(db, 'users', `${instaUsername}/ads/${adID}`), {
         pending: false
      })

  } catch(err){
      throw err;
  }
}
const sendEmail = async (customer_email, image_url, creator_email, selected, message) => {
    const emailData = {
      customer_email,
      image_url,
      creator_email,
      post_type: selected,
      message_by_customer: message
    };
  
    try {
      const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });
  
      const result = await response.json();
    } catch (error) {
        throw error;
    }
  };
  
  const sendEmailVideo = async (customer_email, video_url, creator_email, selected, message) => {
    const emailData = {
      customer_email,
      video_url,
      creator_email,
      post_type: selected,
      message_by_customer: message
    };
  
    try {
      const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/sendEmailVideo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });
  
      const result = await response.json();
    } catch (error) {
        throw error;
    }
  };

const getAds = async (username) => {
  try{
    const docSnap = await getDocs(collection(db, 'users', `${username}/ads`));
    return docSnap;
  } catch(err){
      throw err;
  }
}

const getTotalEarned = async (userName) => {
  try {
    const docu = await getDoc(doc(db, 'users', userName));
    if (docu.exists()) {
      const data = docu.data();
      if(data["total_earned"] != null){
        return data["total_earned"];
      }
    }
    return 0;
  } catch(err){
      throw err;
  }
}
const putPendingAds = async (userName, imageUrl, owner, selected, message, videoUrl, mediaType) => {
  try{
    await addDoc(collection(db, 'users', `${userName}/ads`), {
      image_url: imageUrl,
      is_paid: false,
      owner: owner,
      pending: true,
      post_type: selected,
      message: message,
      video_url: videoUrl,
      media_type: mediaType
  })
  } catch(err){
      throw err;
  }
}
const putApprovedAds = async (userName, imageUrl, owner, selected, videoUrl, mediaType) => {
  try{
    const docR = await addDoc(collection(db, 'users', `${userName}/ads`), {
      image_url: imageUrl,
      is_paid: false,
      owner: owner,
      pending: false,
      post_type: selected,
      video_url: videoUrl,
      media_type: mediaType
  });
  const adId = docR.id;
  return adId;
  } catch(err){
      throw err;
  }
}
// const putPostedAds = async (userName, imageUrl, is_paid, owner) => {
//   try{
//     await updateDoc(collection(db, 'users', `${userName}/pending_ads`), {
//       image_url: imageUrl,
//       is_paid: is_paid,
//       owner
//   })
//   } catch(err){
//       throw err;
//   }
// }

const uploadImage = async (file) => {
  try{
    if (file) {
      const timestamp = Date.now();
      const fileRef = ref(storage, `ad_images/${timestamp}_${file.name}`); 

      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      return url;
    }
  } catch(err){
      throw err;
  }
}
const uploadVideo = async (videoFile) => {
  try {
    if (videoFile) {
      const timestamp = Date.now();
      const videoRef = ref(storage, `videos/${timestamp}_${videoFile.name}`);

      await uploadBytes(videoRef, videoFile);
      const url = await getDownloadURL(videoRef);
      return url;
    }
  } catch (error) {
    throw error;
  }
};
const userNameExists = async (userName) => {
  try {
    const docu = await getDoc(doc(db, 'users', userName));
    return docu.exists();
  } catch(err){
      throw err;
  }
}
const getUserEmail = async (userName) => {
  try {
    const docu = await getDoc(doc(db, 'users', userName));
    if(docu.exists()){
      return docu.data().email;
    }
    return null;
  } catch(err){
      throw err;
  }
}
const getUserPrices = async (userName) => {
  try {
    const docu = await getDoc(doc(db, 'users', userName));
    if(docu.exists()){
      let res = {};
      res["priceStory"] = docu.data().price_per_story;
      res["pricePost"] = docu.data().price_per_post;

      return res;
    }
    return null;
  } catch(err){
      throw err;
  }
}
const getUserProfilePic = async (userName) => {
  try {
    const docu = await getDoc(doc(db, 'users', userName));
    if(docu.exists()){
      return docu.data().profile_pic_link;
    }
    return null;
  } catch(err){
      throw err;
  }
}
const getClientSecret = async (userName, adId, type, customerEmail) => {
  const reqData = {
    userName: userName,
    adId: adId,
    type: type,
    customerEmail
  }
  try{
    const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/getClientSecret', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqData),
    });

    const result = await response.json();
    return result;
  } catch(err){
      throw err;
  }
}
const getCheckoutPageData = async (userName, adId, type) => {
  const reqData = {
    userName: userName,
    adId: adId,
    type: type,
  }
  try{
    const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/getCheckoutPageData', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqData),
    });

    const result = await response.json();
    return result;
  } catch(err){
      throw err;
  }
}
const getInstagramUserData = async (code) => {
  const reqData = {
    response: code
  }
  try{
    const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/getInstagramUserData', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqData),
    });

    const result = await response.json();
    return result.user_obj;
  } catch(err){
      throw err;
  }
}

const sendApproveEmail = async (customer_email, image_url, userName, type, adId) => {
  const emailData = {
    customer_email,
    image_url,
    userName: userName,
    type: type,
    adId: adId
  };

  try {
    const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/sendApproveEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData),
    });

    const result = await response.json();
  } catch (error) {
      throw error;
  }
};
const sendApproveEmailVideo = async (customer_email, video_url, userName, type, adId) => {
  const emailData = {
    customer_email,
    video_url,
    userName: userName,
    type: type,
    adId: adId
  };

  try {
    const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/sendApproveEmailVideo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData),
    });

    const result = await response.json();
  } catch (error) {
      throw error;
  }
};
const createConnectedAccount = async (email, userName) => {
  try {
    const reqData = {
      email: email,
      userName: userName
    }
    try{
      const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/createConnectedAccount', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqData),
      });
  
      const result = await response.json();
      return result;
    } catch(err){
      console.log(err)
        throw err;
    }
  } catch(err){
      throw err;
  }
}
const checkForPaymentSetup = async (userName) => {
  try {
    const reqData = {
      userName: userName
    }
    try{
      const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/retrieveAccount', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqData),
      });
  
      const result = await response.json();
      return result;
    } catch(err){
        throw err;
    }
  } catch(err){
      throw err;
  }
}
// const sendPaymentConfirmationEmail = async (customer_email, image_url, userName) => {
//   const emailData = {
//     customer_email,
//     image_url,
//     userName
//   };

//   try {
//     const response = await fetch('http://127.0.0.1:5001/spotlyt-12782/us-central1/sendPaymentConfirmationEmail', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(emailData),
//     });

//     const result = await response.json();
//     console.log(result);
//   } catch (error) {
//     console.error('Error sending email:', error);
//   }
// };
const confirmPaymentIntent = async (paymentIntentClientSecret, adId, userName, customerEmail, adPrice) => {
  try {
    const reqData = {
      userName: userName,
      adId: adId,
      clientSecret: paymentIntentClientSecret,
      customerEmail: customerEmail,
      adPrice: adPrice
    }
    try{
      const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/confirmPaymentIntent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqData),
      });
  
      const result = await response.json();
      return result;
    } catch(err){
        throw err;
    }
  } catch(err){
      throw err;
  }
}
const checkBalance = async (userName) => {
  try {
    const reqData = {
      userName: userName
    }
    try{
      const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/checkBalance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqData),
      });
  
      const result = await response.json();
      return result;
    } catch(err){
        throw err;
    }
  } catch(err){
      throw err;
  }
}
const createExpressLoginLink = async (userName) => {
  try {
    const reqData = {
      userName: userName
    }
    try{
      const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/expressLink', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqData),
      });
  
      const result = await response.json();
      return result.url;
    } catch(err){
        throw err;
    }
  } catch(err){
      throw err;
  }
}
const updateProfilePic =  async (userName, imgUrl) => {
  try{
      await updateDoc(doc(db, 'users', userName), {
          profile_pic_link: imgUrl
      })

  } catch(err){
      throw err;
  }
}

const deleteAd = async (username, adId) => {
  try {
    await deleteDoc(doc(db, 'users', `${username}/ads/${adId}`));
  } catch(err){
      throw err;
  }
}

const putWaitlist = async (email) => {
  try {
    const docR = await addDoc(collection(db, 'waitlist'), {
      email
    });
  } catch(err){
      throw err;
  }
}
const sendVerificationEmail = async (email) => {
  try {
    const reqData = {
      email: email
    }
    try{
      const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/sendVerificationEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqData),
      });
  
      const result = await response.json();
      return result;
    } catch(err){
        throw err;
    }
  } catch(err){
      throw err;
  }
}
const sendResetPasswordEmail = async (email) => {
  try {
    const reqData = {
      email: email
    }
    try{
      const response = await fetch('https://us-central1-spotlyt-12782.cloudfunctions.net/sendResetPasswordEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqData),
      });
  
      const result = await response.json();
      return result;
    } catch(err){
        throw err;
    }
  } catch(err){
      throw err;
  }
}
export {
    putUser,
    updatePrices,
    sendEmail,
    getAds,
    putPendingAds,
    uploadImage,
    userNameExists,
    getUserEmail,
    getClientSecret,
    getInstagramUserData,
    sendApproveEmail,
    createConnectedAccount,
    checkForPaymentSetup,
    confirmPaymentIntent,
    checkBalance,
    createExpressLoginLink,
    addToApproved,
    putApprovedAds,
    updateProfilePic,
    getUserProfilePic,
    deleteAd,
    getUserPrices,
    putWaitlist,
    getCheckoutPageData,
    getTotalEarned,
    sendVerificationEmail,
    sendResetPasswordEmail,
    uploadVideo,
    sendApproveEmailVideo,
    sendEmailVideo
}
