import React, { useState } from 'react';
import '../styles/css/RadioInput.css'; // Import CSS file for styling


const RadioInput = (props) => {

const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    console.log(props.value)
    props.setSelected(props.value)
  };

  return (
    <div>
      <label className="checkbox-container">
        {props.title}
       {!props.isChecked && <span className='boxIN' style={{border: props.colorBorder ? '0.4px solid black': '0.4px solid white'}}></span>}
        <input
          type="checkbox"
          checked={props.isChecked}
          onChange={handleCheckboxChange}
        />
            <span className={`checkmark ${!props.isChecked ? 'checked' : ''}`}>✅</span>
      </label>
    </div>
  );
};
  
  export default RadioInput;
  