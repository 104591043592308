import styles from '../styles/css/VerifyEmail.module.css';
import Navbar from "../components/navbar";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import {confirmPaymentIntent} from '../service/dataService';
import { Oval } from  'react-loader-spinner'
import greenC from '../assets/images/greenC.png';
import {handleVerifyEmail} from '../service/firebase';

const VerifyEmail = (props) => {
    const searchParams = new URLSearchParams(window.location.search);
    const [isVerified, setIsVerified] = useState(null);
    
    async function startVerification() {
        try{
            const oobCode = props.actionCode;
            await handleVerifyEmail(oobCode);
            setIsVerified(true);
        } catch(err){
            setIsVerified(false);
            console.log(err);
        }
    }
    useEffect(() => {
        startVerification();
    }, [])

    if(isVerified == null) {
        return (
            <div className={styles.loading}>
                <Oval
                    height={80}
                    width={80}
                    color="#ffffff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#ffffff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
        ) 
    }
    if(!isVerified) {
        return (
            <div className={styles.loading}>
                <div style={{color: 'white'}}>This page is not available.</div>
            </div>
        ) 
    }
    return (
        <div className={styles.container}>
        <div className={styles.overlay}></div>
            <Navbar/>
            <div className={styles.box}>
                <img src={greenC} className={styles.greenC}/>
                <div className={styles.stat}>
                   Your Email has been verified
                </div>
                <div className={styles.thankText}>
                    You can continue using Spotlyt.
                </div>
                <div className={styles.signUpBtn} onClick={() => window.location.href = `https://www.spotlyt.app/signin`}>
                    Sign in
                </div>
            </div>
        </div>
    )
}
export default VerifyEmail;