import Modal from "react-modal";
import { AiFillCloseCircle } from "react-icons/ai";
import styles from "../styles/css/PaymentReminder.module.css";
import { Oval } from "react-loader-spinner";
import { useState } from "react";

const VideoErrModal = (props) => {
    
  return (
    <Modal
      isOpen={props.modalIsOpen}
      style={{
        overlay: {
          zIndex: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgb(0,0,0, 0.7)",
        },
        content: {
          zIndex: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          boxSizing: "border-box",
          overflow: "hidden",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "transparent",
        },
      }}
    >
      <div className={styles.modalContVidErr}>
        <div className={styles.titlesVidErr} style={{ color: "white" }}>
        Vidoes can not exceed 59 seconds.
        </div>
        <div className={styles.pText}>
            Please try again
        </div>
        <div className={styles.cl} onClick={() => props.setIsOpen(false)}>Close</div>
      </div>
    </Modal>
  );
};
export default VideoErrModal;
