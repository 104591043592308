import Navbar from "../components/navbar";
import styles from '../styles/css/PaymentConfirmation.module.css';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import {confirmPaymentIntent} from '../service/dataService';
import { Oval } from  'react-loader-spinner'
import greenC from '../assets/images/greenC.png';
import { useNavigate } from 'react-router-dom';

const PaymentConfirmation = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const paymentIntent = searchParams.get('payment_intent');
    const paymentIntentClientSecret = searchParams.get('payment_intent_client_secret');
    const [loading, setLoading] = useState(true);
    const {adId, userName, customerEmail, adPrice } = useParams();
    const [status, setStatus] = useState(null);
    const navigate = useNavigate();

    const confirmPayment = async () => {
        try{
            const res = await confirmPaymentIntent(paymentIntent, adId, userName, customerEmail, adPrice);
            if(res.status == "success")
                setStatus("Payment successful!")
            else if(res.status == "failed")
                setStatus("Payment failed. Please try another payment method.")
            else if(res.status == "Something went wrong")
                setStatus("Something went wrong.")
            else 
                setStatus("Something went wrong.")
        } catch(err){
            console.log(err);
        }
    }
    useEffect(() => {
        confirmPayment();
    },[])

    if(status == null){
        return (
            <div className={styles.loading}>
                <Oval
                    height={80}
                    width={80}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
                <div style={{color: 'white', marginTop: '20px'}}>Please dont close this window</div>
            </div>
        )
    }
    return (
        <div className={styles.container}>
        <div className={styles.overlay}></div>
            <Navbar/>
            {status == "Payment successful!" ? <div className={styles.box}>
                <img src={greenC} className={styles.greenC}/>
                <div className={styles.stat}>
                    {status}
                </div>
                <div className={styles.thankText}>
                    Thank you for using Spotlyt.  
                    Your post will go live within 48 hours.  Please contact team@spotlyt.app if you have any questions.
                </div>
                <div className={styles.signUpBtn} onClick={() => window.location.href = `https://www.spotlyt.app/${userName}`}>
                    Got it
                </div>
            </div>: 
            <div className={styles.box}>
                <div style={{ color: "#e06666" }}>{status}</div>  
            </div>}
        </div>
    )
}
export default PaymentConfirmation;