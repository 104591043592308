import { useEffect } from 'react';
import { useState } from 'react';
import { Oval } from  'react-loader-spinner'
import VerifyEmail from './verifyEmail';
import ResetPassword from './resetPassword';

const ManageUser = () => {
    const searchParams = new URLSearchParams(window.location.search);

    const [mode, setMode] = useState('');
    const [actionCode, setActionCode] = useState('');

    async function startVerification() {
        try{
            const oobCode = searchParams.get('oobCode');
            const mode = searchParams.get('mode');
            console.log(oobCode);
            
            console.log(mode)
            setMode(mode);
            setActionCode(oobCode);
        } catch(err){
            console.log(err);
        }
    }
    useEffect(() => {
        startVerification();
    }, [])


    if(mode == '') {
        return (
            <div style={{ width: "100%",
                height: "100vh",
                backgroundColor: "black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"}}>
                <Oval
                    height={80}
                    width={80}
                    color="#ffffff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#ffffff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
        ) 
    }
    if(mode == "verifyEmail") {
        return <VerifyEmail actionCode={actionCode}/>
    }
    if(mode == "resetPassword") {
        return <ResetPassword actionCode={actionCode}/>
    }
    else {
        return (
            <div style={{ width: "100%",
                height: "100vh",
                backgroundColor: "black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"}}>
                    <div style={{color: 'white'}}>Page not available</div>
            </div>
        ) 
    }
}
export default ManageUser;