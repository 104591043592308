import styles from "../styles/css/Signup.module.css";
import { useState, useEffect } from "react";
import { useAuth } from "../service/authContext";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import logo from "../assets/images/spotlyt_logo.png";
import { putUser, getInstagramUserData } from "../service/dataService";
import { TfiInstagram } from "react-icons/tfi";
import { InstagramLogin } from "@amraneze/react-instagram-login";
import axios from "axios";
import insta from "../assets/images/instagram_wordmark_white.png";
import { updateDisplayName } from "../service/firebase";

const VerifyInstagram = () => {
  const user = useAuth();
  const navigation = useNavigate();
  const [instaUserName, setInstaUsername] = useState(null);
  const [instaDisplay, setInstaDisplay] = useState("");

  const handleNext = async () => {
    try {
      await updateDisplayName(instaUserName);
      navigation("/prices");
    } catch (err) {
      console.log(err);
    }
  };
  const responseInstagramError = (res) => {
    console.log(res);
  };
  const responseInstagram = async (response) => {
    try {
      const userObj = await getInstagramUserData(response);
      const res = await axios.get(
        `https://graph.instagram.com/me?fields=username,account_type&access_token=${userObj.access_token}`
      );
      console.log(res.data);
      setInstaUsername(res.data.username);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (instaUserName != null) {
      handleNext();
    }
  }, [instaUserName]);

  return (
    <div className={styles.container}>
      <Navbar />

      <div className={styles.topC}>
        <div className={styles.overlay}></div>
        <div className={styles.abs}>
          <img src={logo} className={styles.lgo} />

          <div className={styles.formCSignIn}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div className={styles.welc} style={{ marginBottom: "10px" }}>
                Congrats, Welcome to Spotlyt!
              </div>
              <div className={styles.welc}>Create a Username</div>
            </div>
            <div className={styles.innerF}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: '35px'
                }}
              >
                <TfiInstagram size={20} color="#999999" />
                <input
                  className={styles.inp}
                  placeholder="Username"
                  style={{ width: "100%", marginLeft: "5px"}}
                  type="text"
                  onChange={(e) => setInstaDisplay(e.target.value)}
                  value={instaUserName ? instaUserName : instaDisplay}
                />
              </div>
              {/* <div
                className={styles.signUpBtn2}
                onClick={() => {
                  navigation("/prices");
                }}
              >
                Continue with Spotlyt
              </div> */}
              <InstagramLogin
                clientId="231662468880803"
                onSuccess={responseInstagram}
                onFailure={responseInstagramError}
                redirectUri="https://www.spotlyt.app/verify"
                scope="user_profile"
                cssClass={styles.instaButton}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "6px" }}>
                    Verify with
                  </div>
                  <img src={insta} className={styles.instaLogo}/>   
                </div>
              </InstagramLogin>

              {/* <div className={styles.signUpBtn} onClick={() => handleNext()}>
                All Done
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                  color: "#5b5b5b",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigation("/prices");
                }}
              >
                Verify later
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VerifyInstagram;
